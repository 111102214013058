import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

import PropTypes from 'prop-types';

const ButtonLoader = ({
  height,
  width,
  radius,
  ...props
}: IContentLoaderProps) => (
  <ContentLoader viewBox={`0 0 ${width} ${height}`} height={height} {...props}>
    <rect x="0" y="0" rx={radius} ry={radius} width="100%" height={height} />
  </ContentLoader>
);

ButtonLoader.propTypes = {
  speed: PropTypes.number,
  radius: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
};

ButtonLoader.defaultProps = {
  speed: 2,
  width: '100%',
  height: 30,
  radius: 15,
  backgroundColor: '#f3f3f3',
  foregroundColor: '#ecebeb',
};

export default ButtonLoader;
