export function adjustColour(color, amount) {
  return `#${color
    .replace(/^#/, '')
    .replace(/../g, currColor =>
      `0${Math.min(255, Math.max(0, parseInt(currColor, 16) + amount)).toString(
        16,
      )}`.substr(-2),
    )}`;
}

const changeThemeColor = themeColor => {
  document.documentElement.style.setProperty('--primary', themeColor);

  document.documentElement.style.setProperty(
    '--primary-darker',
    adjustColour(themeColor, -20),
  );

  document.documentElement.style.setProperty(
    '--primary-light',
    adjustColour(themeColor, 80),
  );

  document.documentElement.style.setProperty(
    '--primary-lightest',
    adjustColour(themeColor, 140),
  );
};

export default changeThemeColor;
