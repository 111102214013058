import { Fragment } from 'react';

import EntityFormModal from 'components/Entity/EntityFormModal/EntityFormModal';
import { useDialogManager } from 'contexts/DialogManagerContext';

const DialogManager = () => {
  const { state, closeDialog } = useDialogManager();

  return state.dialogs.map(({ id, params }, index) => (
    <Fragment key={id}>
      <EntityFormModal
        show
        handleClose={() => closeDialog(id)}
        entityId={params.entityId}
        entityKey={params.entityKey}
        modalLevel={index}
      />
    </Fragment>
  ));
};

export default DialogManager;
