import ContentLoader from 'react-content-loader';

import PropTypes from 'prop-types';

type Props = {
  rows: number;
  radius?: number;
};

const CommentsLoader = ({ rows, radius, ...props }: Props) => {
  const comments: Array<any> = [];
  const OFFSET = 83;
  for (let i = 0; i < rows; i += 1) {
    comments.push(
      <circle key={1 + i * 4} cx="20" cy={36 + i * OFFSET} r="20" />,
      <rect
        rx={radius}
        ry={radius}
        key={2 + i * 4}
        x="48"
        y={19 + i * OFFSET}
        width="221"
        height="17"
      />,
      <rect
        rx={radius}
        ry={radius}
        key={3 + i * 4}
        x="48"
        y={52 + i * OFFSET}
        width="221"
        height="17"
      />,
    );
  }
  const height = OFFSET * rows;
  return (
    <ContentLoader viewBox={`0 0 270 ${height}`} height={height} {...props}>
      {comments}
    </ContentLoader>
  );
};
CommentsLoader.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rows: PropTypes.number,
  radius: PropTypes.number,
};

CommentsLoader.defaultProps = {
  width: 270,
  radius: 3,
  rows: 4,
};

export default CommentsLoader;
