import { useMemo } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { getOutcomeLabel } from '../../utils/worflowUtils';

const WorkflowInfo = ({ workflow }) => {
  const currentStep = useMemo(
    () =>
      workflow?.definition?.steps
        ? workflow.definition.steps.find(
            step => step.order === workflow.currentStep,
          )
        : undefined,
    [workflow],
  );

  return (
    <div className="d-flex align-items-center" data-cy="workflow-info">
      <span
        className={cx('badge text-black', {
          'badge-success': workflow.outcome === 1,
          'badge-danger': workflow.outcome === 2,
          'badge-warning': !workflow.outcome,
        })}
        data-cy="workflow-current-step"
      >
        <div>
          {getOutcomeLabel(workflow.definition, workflow.outcome) ||
            currentStep?.name}
        </div>
      </span>{' '}
      {workflow && workflow.ownerName && (
        <div className="ml-1" data-cy="workflow-assigned-to">
          assigned to{' '}
          <strong className="ml-1">{workflow && workflow.ownerName}</strong>
        </div>
      )}
    </div>
  );
};

WorkflowInfo.propTypes = {
  workflow: PropTypes.shape({
    ownerName: PropTypes.string,
    outcome: PropTypes.number,
    definition: PropTypes.any,
    currentStep: PropTypes.number,
  }).isRequired,
};

export default WorkflowInfo;
