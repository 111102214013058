import cx from 'classnames';
import PropTypes from 'prop-types';

const FileIcon = ({ type, size }) => {
  const icons = {
    'application/pdf': (
      <i className={cx('far fa-file-pdf', { [size]: size })} />
    ),
    'image/jpeg': <i className={cx('far fa-file-image', { [size]: size })} />,
    'image/png': <i className={cx('far fa-file-image', { [size]: size })} />,
    'image/gif': <i className={cx('far fa-file-image', { [size]: size })} />,
    'video/mpeg': <i className={cx('far fa-file-video', { [size]: size })} />,
    'video/x-msvideo': (
      <i className={cx('far fa-file-video', { [size]: size })} />
    ),
    'application/msword': (
      <i className={cx('far fa-file-word', { [size]: size })} />
    ),
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (
      <i className={cx('far fa-file-excel', { [size]: size })} />
    ),
  };

  return icons[type] || <i className={cx('far fa-file', { [size]: size })} />;
};

FileIcon.propTypes = {
  type: PropTypes.string,
  size: PropTypes.any,
};

FileIcon.defaultProps = {
  type: '',
  size: '',
};

export default FileIcon;
