import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import PropTypes from 'prop-types';

import FieldLabel from './FieldLabel';

const NumericField = ({ name, defaultValue, fieldItem }) => {
  const { register, errors } = useFormContext();

  const placeholder = fieldItem?.placeholder;
  const readOnly = fieldItem.ui_component_options?.readOnly || false;

  const inputProps = {
    name,
    className: cx('form-control', { 'is-invalid': errors[name] }),
    placeholder,
    id: name,
    ref: register(),
    readOnly,
    defaultValue,
  };
  return (
    <FieldLabel fieldItem={fieldItem}>
      <input type="number" {...inputProps} />
      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}
    </FieldLabel>
  );
};

NumericField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.shape({
      readOnly: PropTypes.bool,
    }),
  }).isRequired,
};

NumericField.defaultProps = {
  defaultValue: '',
};

export default NumericField;
