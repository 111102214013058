import { capitalize } from 'utils/text';

import s from './EnvironmentStatusBar.module.scss';

type EnvironmentStatusBar = {
  environmentType: string;
};

const EnvironmentStatusBar = ({ environmentType }: EnvironmentStatusBar) => (
  <div className={s.root}>
    <small className="my-0">
      {capitalize(environmentType)} - This is a non-production environment.
      Changes performed here will NOT affect production systems.
    </small>
  </div>
);

export default EnvironmentStatusBar;
