import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

import cx from 'classnames';
import PropTypes from 'prop-types';

import FieldLabel from './FieldLabel';

const OptionSet = ({ name, defaultValue, fieldItem }) => {
  const { control, errors } = useFormContext();

  const componentOptionValues = fieldItem.ui_component_options?.values || [];
  const options = componentOptionValues.map(value => ({
    label: value,
    value: value,
  }));

  const readOnly = fieldItem.ui_component_options?.readOnly || false;
  const placeholder = fieldItem?.placeholder;

  const transformedDefaultValue = defaultValue
    ? {
        value: defaultValue,
        label: defaultValue,
      }
    : undefined;

  return (
    <FieldLabel fieldItem={fieldItem}>
      <Controller
        as={ReactSelect}
        styles={{
          control: provided => ({
            ...provided,
            borderColor: errors[name] ? 'red' : '#ced4da',
            minHeight: '3rem',
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
        isDisabled={readOnly}
        isClearable
        className={cx('single-dropdown', { 'is-invalid': errors[name] })}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        control={control}
        options={options}
        defaultValue={transformedDefaultValue}
        name={name}
        id={name}
      />

      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}
    </FieldLabel>
  );
};

OptionSet.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.shape({
      values: PropTypes.array,
    }),
  }).isRequired,
  data: PropTypes.any,
};

OptionSet.defaultProps = {
  defaultValue: '',
};

export default OptionSet;
