import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { FilterFormValue } from '@april9/stack9-react';
import { FieldItem, WorkflowDefinition } from '@april9/stack9-sdk';
import cx from 'classnames';
import { parseFormFilterLabel } from 'utils/filters';

import AppliedFilterLoader from 'components/Loaders/AppliedFilterLoader';
import { useDeepCompareMemo } from 'hooks/useDeepCompare';

import {
  DateRangePickerField,
  DefaultField,
  MultiDropDown,
  NumericField,
  WorkflowStepField,
} from './FilterFields';
import FilterPopover from './FilterPopover';
import { useQuestringValuesParser } from './Hooks/useQuestringValuesParser';

import s from './AppliedFilter.module.scss';

type Props = {
  values: FilterFormValue;
  filterKey: string;
  fieldItem: FieldItem;
  workflowDefinition: WorkflowDefinition;
  removeAppliedFilter: (filterKey: string) => void;
  handleSubmit: () => void;
};

const AppliedFilter = ({
  values,
  filterKey,
  fieldItem,
  workflowDefinition,
  removeAppliedFilter,
  handleSubmit,
}: Props) => {
  const { errors } = useFormContext();

  const { loading, defaultValue } = useQuestringValuesParser(
    values,
    fieldItem,
    workflowDefinition,
  );

  const selectedFiltersLabel = useDeepCompareMemo(
    () => parseFormFilterLabel(defaultValue, fieldItem, workflowDefinition),
    [defaultValue, fieldItem, workflowDefinition],
  );

  const uiComponent = fieldItem.ui_component;
  const { label } = fieldItem;

  const name = `${filterKey}.value`;
  const fieldItemLabel = fieldItem?.label;

  if (loading) {
    return (
      <div className={s.loader}>
        <AppliedFilterLoader />
      </div>
    );
  }

  const fieldComponent = {
    WorkflowStep: (
      <WorkflowStepField
        steps={workflowDefinition?.steps}
        labelForFinalised={workflowDefinition?.outcome?.success.label}
        labelForRejected={workflowDefinition?.outcome?.failure.label}
        name={name}
        defaultValue={defaultValue}
      />
    ),
    MultiDropDown: (
      <MultiDropDown
        name={name}
        uiComponentOptions={fieldItem.ui_component_options}
        relationshipOptions={fieldItem.relationshipOptions}
        defaultValue={defaultValue}
      />
    ),
    SingleDropDown: (
      <MultiDropDown
        name={name}
        uiComponentOptions={fieldItem.ui_component_options}
        relationshipOptions={fieldItem.relationshipOptions}
        defaultValue={defaultValue}
      />
    ),
    OptionSet: (
      <MultiDropDown
        name={name}
        options={(fieldItem.ui_component_options?.values || []).map(value => ({
          label: value,
          value: value,
        }))}
        defaultValue={defaultValue}
      />
    ),
    Checkbox: (
      <MultiDropDown
        name={name}
        options={[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: `No`,
            value: false,
          },
        ]}
        defaultValue={defaultValue}
        uiComponentOptions={fieldItem.ui_component_options}
      />
    ),
    DateField: (
      <DateRangePickerField
        name={name}
        uiComponentOptions={fieldItem.ui_component_options}
        defaultValue={defaultValue as Date[]}
      />
    ),
    NumericField: (
      <NumericField name={name} defaultValue={defaultValue as number} />
    ),
    default: <DefaultField name={name} defaultValue={defaultValue} />,
  };

  return (
    <div
      data-cy="applied-filter-item"
      className={cx(s.root, {
        [s.isInvalid]: errors[filterKey],
      })}
    >
      <FilterPopover
        className={s.appliedFilterDropdown}
        id={`applied-filter-field-${filterKey}`}
        onApply={handleSubmit}
        title={
          <button
            type="button"
            className={cx('btn btn-sm btn-secondary', s.appliedFilter)}
          >
            <label className={s.label}>{label}:</label> {selectedFiltersLabel}
          </button>
        }
      >
        <div className="form-group mb-0">
          <label htmlFor={name}>{fieldItemLabel}</label>
          {fieldComponent[uiComponent] || fieldComponent.default}
        </div>
      </FilterPopover>

      <button
        type="button"
        className={cx('btn btn-sm btn-secondary', s.removeAppliedFilter)}
        onClick={() => removeAppliedFilter(filterKey)}
        data-cy="remove-applied-filter"
      >
        <i className="fas fa-times" />
      </button>
    </div>
  );
};

AppliedFilter.defaultProps = {
  values: '',
};

export default memo(AppliedFilter);
