const getSortPath = path => {
  const segments = path.split('.');
  const result =
    segments.slice(0, segments.length - 1).join(':') +
    (segments.length > 1 ? '.' : '') +
    segments[segments.length - 1];

  return result;
};

export { getSortPath };
