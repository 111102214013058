import axios from 'axios';
import { getCookie, deleteCookie } from 'utils/cookie';

const API = process.env.REACT_APP_API;
const STACK9_API = process.env.REACT_APP_A9_STACK9_API;

let isRefreshing = false;
let failedQueue: Array<any> = [];

const apiToken = getCookie('api-token');
const api = axios.create({
  baseURL: `${STACK9_API}`,
  headers: apiToken
    ? {
        Authorization: `Bearer ${apiToken}`,
      }
    : {},
});

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

api.interceptors.response.use(
  response => response, // 200
  error => {
    const originalRequest = error.config;

    // Handling Unauthorised
    if (
      error.response.status === 401 &&
      !error.response.headers['token-expired']
    ) {
      deleteCookie('api-token');
      window.location.href = '/';

      // Redirect
      throw new Error('Not implemented exception');
    }

    // Handling Token Expired
    if (
      error.response.status === 500 &&
      error.response.data.error &&
      error.response.data.error.includes('TokenExpiredError')
    ) {
      deleteCookie('api-token');
      window.location.href = '/';
      return Promise.resolve();
    }

    // Handling Token Expired
    if (
      error.response.status === 401 &&
      error.response.headers['token-expired'] &&
      !originalRequest.retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() =>
            // param token here if needed
            api(originalRequest),
          )
          .catch(err => Promise.reject(err));
      }

      originalRequest.retry = true;
      isRefreshing = true;

      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      return new Promise((resolve, reject) => {
        api
          .post(`${API}/admin/api/v1/Identity/Refresh`, { token, refreshToken })
          .then(({ data }) => {
            localStorage.setItem('token', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
            api.defaults.headers.common.Authorization = `Bearer ${data.token}`;
            originalRequest.headers.Authorization = `Bearer ${data.token}`;
            processQueue(null, data.token);
            resolve(api(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);

export default api;
