import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

import cx from 'classnames';
import PropTypes from 'prop-types';

const WorkflowReasonModal = ({
  show,
  title,
  body,
  confirmType,
  confirmAction,
  confirmActionText,
  handleClose,
  modalLevel,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    confirmAction(data);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdropClassName={`modal-level-${modalLevel}`}
      className={`modal-level-${modalLevel}`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title>
            <h5>{title}</h5>
          </Modal.Title>
          <div
            className="toggle-icon"
            onClick={handleClose}
            onKeyDown={handleClose}
            role="button"
            tabIndex={-1}
          >
            <i className="fas fa-times" />
          </div>
        </Modal.Header>

        <Modal.Body className="p-4">
          {body}

          <div className="form-group">
            <textarea
              className={cx('form-control', {
                'is-invalid': errors.outcome_reason?.message,
              })}
              name="outcome_reason"
              id="outcome_reason"
              ref={register({ required: true })}
            />

            <span className="text-danger">
              {errors.outcome_reason?.message}
            </span>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-start">
          <button
            className={`btn btn-${confirmType}`}
            type="submit"
            id="saveAndReturn"
          >
            {confirmActionText}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

WorkflowReasonModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string.isRequired,
  confirmType: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
  confirmActionText: PropTypes.string,
  handleClose: PropTypes.func,
  modalLevel: PropTypes.number,
};

WorkflowReasonModal.defaultProps = {
  show: false,
  title: 'WorkflowReason',
  confirmType: 'primary',
  confirmActionText: 'Confirm',
  handleClose: () => {},
  modalLevel: 50,
};

export default WorkflowReasonModal;
