import PropTypes from 'prop-types';

import { AgGrid } from 'components/ThirdLibraries';

const GridSettings = ({ rowData }) => {
  if (!rowData.length) {
    return <span>No data table defined for this entity.</span>;
  }

  return (
    <AgGrid
      columnDefs={[
        { headerName: 'Column Header', field: 'headerName' },
        { headerName: 'Column Field', field: 'field' },
        { headerName: 'Sortable', field: 'sortable' },
      ]}
      rowData={rowData}
      onGridReady={agGridApi => agGridApi.hideOverlay()}
    />
  );
};

GridSettings.propTypes = {
  rowData: PropTypes.array,
};

GridSettings.defaultProps = {
  rowData: [],
};

export default GridSettings;
