import { FilterFormKeyValue } from '@april9/stack9-react';
import {
  FilterFieldsSchema,
  IJsonEntityView,
  S9Query,
} from '@april9/stack9-sdk';
import { mergeViewAndQueryParams } from 'utils/entity';
import {
  filterValidFormValues,
  transformFiltersDataBeforeSubmit,
} from 'utils/filters';
import { deserializeQuery, serializeQuery } from 'utils/objectTransformer';

import { normalizeValueToArray } from 'constants/filterCriterias';

export class SearchRequest {
  public static DefaultGroupLimit = 1000;
  public static DefaltQueryLimit = 25;
  public static DefaultMapLimit = 10000;
  public static DefaultExportLimit = 10000;

  public view: string | undefined = undefined;
  public page = 0;
  public sort: string[] = [];
  public groupBy: string[] = [];
  public pageLimit = SearchRequest.DefaltQueryLimit;
  public deleted = false;

  private _defaultQuery: S9Query = {
    $withRelated: [],
    $where: {
      _is_deleted: this.deleted,
    },
    $select: [],
  };

  public query: S9Query | undefined;

  public filterFormValues: FilterFormKeyValue | undefined;

  private assign(instance: Partial<SearchRequest>): SearchRequest {
    return Object.assign(new SearchRequest(), {
      ...this,
      ...instance,
    });
  }

  public updateFilterFormValues(
    filterFormValues: FilterFormKeyValue,
  ): SearchRequest {
    return this.assign({ filterFormValues });
  }

  public buildQuery(
    filterFields: FilterFieldsSchema,
    view: IJsonEntityView,
  ): S9Query {
    if (!this.filterFormValues) {
      return mergeViewAndQueryParams(this.query || this._defaultQuery, view);
    }

    const validFilterFormValues = filterValidFormValues(
      this.filterFormValues,
      filterFields,
    );

    const s9Query = transformFiltersDataBeforeSubmit(
      validFilterFormValues,
      filterFields,
    );

    const $groupBy = this.groupBy?.length ? this.groupBy : undefined;

    const $sort =
      !$groupBy && this.sort?.length > 1
        ? { [this.sort[0]]: this.sort[1] }
        : undefined;

    const queryBuilt: S9Query = {
      ...this._defaultQuery,
      ...s9Query,
      $sort,
      $groupBy,
      $where: { ...s9Query.$where, _is_deleted: this.deleted },
    };

    return mergeViewAndQueryParams(queryBuilt, view);
  }

  public updateQuery(query: S9Query): SearchRequest {
    return this.assign({
      query: {
        ...this.query,
        ...query,
        $where: { ...query.$where, _is_deleted: this.deleted },
      },
    });
  }

  public setLimit(pageLimit: number): SearchRequest {
    return this.assign({ pageLimit });
  }

  public setView(view: string) {
    return this.assign({ view });
  }

  public setPage(page: number): SearchRequest {
    return this.assign({ page });
  }

  public setSort(sort: string[]) {
    return this.assign({ sort });
  }

  public setGroupBy(groupBy: string[]) {
    return this.assign({ groupBy });
  }

  public toQuerystring(): string {
    const {
      view,
      page,
      sort,
      groupBy,
      pageLimit,
      deleted,
      // query,
      filterFormValues,
    } = this;

    return serializeQuery({
      view,
      page,
      sort,
      groupBy,
      pageLimit,
      deleted,
      // query: !filterFormValues ? JSON.stringify(query) : undefined,
      ...filterFormValues,
    });
  }

  public fromQuerystring(querystring: string): SearchRequest {
    if (!querystring) {
      return new SearchRequest();
    }

    const {
      view,
      page,
      sort,
      groupBy,
      pageLimit,
      deleted,
      //   query,
      ...filterFormValues
    } = deserializeQuery(querystring);

    return this.assign({
      view,
      page: page ? parseInt(page, 10) : this.page,
      sort,
      groupBy: groupBy ? normalizeValueToArray(groupBy) : undefined,
      pageLimit: pageLimit ? parseInt(pageLimit, 10) : this.pageLimit,
      deleted: deleted?.toLowerCase() === 'true',
      //     query: query ? JSON.parse(query) : undefined,
      filterFormValues,
    });
  }
}
