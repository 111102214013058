import { useState, useEffect, useContext } from 'react';

import { IEntitySchemaDefinition, IJsonEntityView } from '@april9/stack9-sdk';
import buildRelatedGridOptions from 'utils/buildRelatedGridOptions';
import { getSortPath } from 'utils/stringUtils';

import { AgGrid } from 'components/ThirdLibraries';
import { AppContext } from 'contexts/AppContext';

import s from './EntityTable.module.scss';

interface IEntityTable {
  schema: IEntitySchemaDefinition;
  view: IJsonEntityView;
  data: any[];
  defaultSort?: string[];
  onRowSelected: (event: any) => any;
  onColumnSorted: (sort: any) => any;
}

const EntityTable = ({
  schema,
  view,
  data: entityData,
  defaultSort,
  onRowSelected,
  onColumnSorted,
}: IEntityTable) => {
  const [gridApi, setGridApi] = useState<any>(null);

  const {
    state: { settings },
  } = useContext(AppContext);

  const onGridReady = gridApi => {
    setGridApi(gridApi);
  };

  const onSortChanged = params => {
    if (params.api) {
      const sortModel = params.api.getSortModel();
      let sort;
      if (sortModel.length > 0) {
        sort = {};

        const path = getSortPath(sortModel[0].colId);

        sort[path] = sortModel[0].sort;
      }

      // TODO: the server-side sort is not working properly.
      // The function is calling fetchAllByEntityName which is
      // resetting the state, re-rendering the agGrid and not saving the
      // previous sort value.
      onColumnSorted(sort);
    }
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.setRowData(entityData);
    }
  }, [entityData, gridApi]);

  const agGridOptions = view.viewOptions!.gridSettings.agGrid;
  if (!agGridOptions) {
    return <span>Loading...</span>;
  }

  const {
    columnDefs,
    defaultColDef,
    suppressHorizontalScroll,
    frameworkComponents,
  } = buildRelatedGridOptions(agGridOptions, schema, settings);

  return (
    <AgGrid
      rowData={entityData || []}
      className={s.grid}
      columnDefs={columnDefs}
      defaultSort={defaultSort}
      onGridReady={onGridReady}
      onCellClicked={onRowSelected}
      onSortChanged={onSortChanged}
      getRowNodeId={data => data.id}
      rowStyle={{
        background: 'white',
        borderColor: 'rgba(85, 52, 230, 0.2)',
      }}
      defaultColDef={defaultColDef}
      immutableData
      suppressHorizontalScroll={suppressHorizontalScroll}
      frameworkComponents={frameworkComponents}
    />
  );
};

export default EntityTable;
