import { useState, useRef } from 'react';

import s from './TagsInput.module.scss';

const TagsInput = ({
  tags = [],
  onChange,
  placeholder = 'Type to add value...',
  delimiter = /\n|\t/gi,
}) => {
  const [selectedTags, setSelectedTags] = useState<string[] | []>(tags);
  const inputRef = useRef<HTMLInputElement>(null);

  const removeTag = indexToRemove => {
    const values = [
      ...selectedTags.filter((_, index) => index !== indexToRemove),
    ];

    setSelectedTags(values);
    onChange(values);
  };

  const removeLastTag = () => {
    if (selectedTags.length === 0) return;

    removeTag(selectedTags.length - 1);
  };

  const resetInputValue = () => {
    setTimeout(() => {
      if (!inputRef.current) return;

      inputRef.current.value = '';
    }, 10);
  };

  const addTags = elValue => {
    if (elValue === '') return;

    const newValues = `${elValue}`
      .split(delimiter)
      .map(value => {
        const trimmedValue = value.trim();
        return trimmedValue;
      })
      .filter(value => !!value);

    const values = [...selectedTags, ...newValues];

    setSelectedTags(values);
    onChange(values);
    resetInputValue();
  };

  return (
    <div
      className={s.root}
      onClick={() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
    >
      <ul className={s.tags}>
        {selectedTags.map((tag, index) => (
          <li key={index} className={s.tag}>
            <span>{tag}</span>
            <span className={s.removeTag} onClick={() => removeTag(index)}>
              x
            </span>
          </li>
        ))}
      </ul>

      <input
        type="text"
        ref={inputRef}
        onPaste={e => {
          if (!e.target) return;

          addTags(e.clipboardData.getData('Text'));
        }}
        onBlur={e => addTags(e.target.value)}
        onKeyDown={e => {
          const { value } = e.target as HTMLInputElement;
          if (e.key === 'Backspace' && value === '') {
            return removeLastTag();
          }

          if (e.key === 'Enter' || (e.key === 'Tab' && value)) {
            e.preventDefault();
            addTags(value);
          }
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TagsInput;
