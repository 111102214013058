import { ReactNode, useContext, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import cx from 'classnames';

import EnvironmentStatusBar from 'components/EnvironmentStatusBar/EnvironmentStatusBar';

import { AppContext } from '../../contexts/AppContext';
import { useAuthContext } from '../../contexts/AuthContext';
import Nav from '../Nav/Nav';

import s from './Layout.module.scss';

interface IProps {
  className?: string;
  children: ReactNode;
}

const LayoutWithMenu = ({ className = '', children }: IProps) => {
  const history = useHistory();
  const match = useRouteMatch<{ appKey: string; entityKey?: string }>(
    '/apps/:appKey/:entityKey?',
  );

  const { logout } = useAuthContext();

  const {
    state: {
      version,
      apps,
      menuItems,
      settings,
      isLoading,
      me,
      environmentType,
    },
    actions: { changeMenuItems },
  } = useContext(AppContext);

  useEffect(() => {
    if (match?.params?.appKey) {
      changeMenuItems(match.params.appKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps]);

  const selectedAppKey = match?.params?.appKey;
  const selectedApp =
    selectedAppKey && !!apps?.length
      ? apps.find(app => app.key === selectedAppKey)
      : {};

  const shouldShowDevelopmentStatusBar =
    environmentType &&
    environmentType !== 'Production' &&
    process.env.REACT_APP_ENV !== 'LOCAL';

  return (
    <>
      {shouldShowDevelopmentStatusBar && (
        <EnvironmentStatusBar environmentType={environmentType} />
      )}

      <Nav
        apps={apps}
        instanceIcon={settings?.instanceIcon}
        instanceName={settings?.name}
        isAdmin={me?.isAdmin}
        loading={isLoading}
        selectedApp={selectedApp}
        menuItems={menuItems}
        onAppClick={app => {
          history.push(`/apps/${app.key}`);
          changeMenuItems(app.key);
        }}
        onLogoutClick={logout}
        onSettingSchemaClick={() => {
          history.push(`/settings/schemas`);
          changeMenuItems(null);
        }}
        version={version}
      />

      <div className={cx(s.root, className)}>
        <main>{children}</main>
      </div>
    </>
  );
};

export default LayoutWithMenu;
