import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';

import cx from 'classnames';
import { Messages } from 'utils/messages';

import Dropzone from 'components/Dropzone/Dropzone';
import FileIcon from 'components/FileIcon/FileIcon';
import { IronBody } from 'components/Layout';
import Moment from 'components/ThirdLibraries/Moment/Moment';
import { useEntityContext } from 'contexts/EntityContext';

import AttachmentLoader from '../../Loaders/AttachmentLoader';

import s from './EntityAttachment.module.scss';

type EntityAttachmentProps = {
  entityKey: string;
  entityId: number;
};

const EntityAttachment = ({ entityKey, entityId }: EntityAttachmentProps) => {
  const [loading, setLoading] = useState(false);
  const [insertLoading, setInsertLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const {
    getEntityState,
    fetchEntityAttachments,
    insertEntityAttachment,
    deleteEntityAttachment,
    fetchCountRelatedEntities,
  } = useEntityContext();

  const entityState = getEntityState(entityKey, entityId);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchEntityAttachments(entityKey, entityId);
      } catch (error) {
        toast.error(error);
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async files => {
    const data = new FormData();
    files.map(file => {
      data.append('files', file);
      return file;
    });
    setInsertLoading(true);
    try {
      const response = await insertEntityAttachment(entityKey, data, entityId);
      if (!response) {
        throw new Error('No response');
      }
    } catch (error) {
      toast.error(error);
      setInsertLoading(false);
      return false;
    }

    try {
      await fetchEntityAttachments(entityKey, entityId);
    } catch (error) {
      toast.error(error);
    }

    setInsertLoading(false);
    toast.success(Messages.AttachmentInserted);

    fetchCountRelatedEntities(entityKey, entityId);

    return true;
  };

  const onDelete = async attachment => {
    const success = await deleteEntityAttachment(
      entityKey,
      entityId,
      attachment.id,
    );

    if (success) {
      toast.success(Messages.AttachmentDeleted);

      fetchEntityAttachments(entityKey, entityId);
      fetchCountRelatedEntities(entityKey, entityId);
    }
  };

  const downloadSelectedItems = () => {
    if (selectedItems.length === 0) return;

    const selectedAttachments = entityState?.attachments.filter(attachment =>
      selectedItems.includes(attachment.id),
    );

    selectedAttachments.forEach(attachment => {
      window.open(
        `/api/${attachment.key}/${attachment.entity_id}/attachment/${attachment.id}`,
      );
    });
  };

  const toggleItem = (id: number) => {
    const items = selectedItems;
    const idx = items.indexOf(id);

    if (idx !== -1) {
      items.splice(idx, 1);
    } else {
      items.push(id);
    }

    setSelectedItems([...items]);
  };

  return (
    <IronBody>
      <Dropzone onSubmit={onSubmit} />
      <div className="mt-4">
        {loading ? (
          <AttachmentLoader rows={1} />
        ) : (
          <>
            {entityState?.attachments?.length > 0 && (
              <>
                <table className={cx('table mb-0 mt-4', s.items)}>
                  <tbody>
                    {entityState?.attachments.map(attachment => (
                      <tr key={attachment.id}>
                        <td
                          width="5%"
                          onClick={() => toggleItem(attachment.id)}
                        >
                          <div className="d-flex align-items-center">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                id={`checkbox-${attachment.id}`}
                                className="custom-control-input"
                                onClick={() => toggleItem(attachment.id)}
                                checked={selectedItems.includes(attachment.id)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`checkbox-${attachment.id}`}
                              >
                                &nbsp;
                              </label>
                            </div>

                            <FileIcon type={attachment.mimetype} size="fa-3x" />
                          </div>
                        </td>

                        <td
                          width="35%"
                          onClick={() => toggleItem(attachment.id)}
                        >
                          <span>{attachment.name}</span>
                          <br />
                          <span className="text-muted">
                            <Moment fromNow>{attachment._created_at}</Moment>
                          </span>
                        </td>
                        <td>
                          <a
                            href={`/api/${attachment.key}/${attachment.entity_id}/attachment/${attachment.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ml-md-4 btn btn-link"
                          >
                            <i className="fas fa-download text-primary" />
                          </a>

                          <button
                            className="ml-md-3 btn btn-link"
                            onClick={() => onDelete(attachment)}
                            type="button"
                          >
                            <i className="far fa-trash-alt text-primary" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <button
                  type="button"
                  className="btn btn-link text-decoration-none"
                  onClick={downloadSelectedItems}
                  disabled={selectedItems.length === 0}
                >
                  <i className="fas fa-download"></i> Download selected
                </button>

                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">
                      Your browser may prevent downloading multiple files. You
                      may need to <strong>allow your browser</strong> to
                      download multiple files for this website.
                    </Tooltip>
                  }
                >
                  <i className="fas fa-info-circle ml-1"></i>
                </OverlayTrigger>
              </>
            )}
          </>
        )}
        {insertLoading && <AttachmentLoader rows={1} />}
      </div>
    </IronBody>
  );
};

export default EntityAttachment;
