import { nanoid } from 'nanoid';

const ActionTypes = {
  OPEN_DIALOG: 'Open a new dialog',
  CLOSE_DIALOG: 'Close existent dialog',
  SET_PARAM: 'Set dialog param',
};

const initialState = {
  dialogs: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_PARAM: {
      const index = state.dialogs.findIndex(
        item => item.id === action.payload.id,
      );

      const { dialogs } = state;

      if (index < 0) {
        return state;
      }

      dialogs[index].params = {
        ...dialogs[index].params,
        ...action.payload.value,
      };

      return {
        dialogs: [...dialogs],
      };
    }
    case ActionTypes.OPEN_DIALOG:
      return {
        dialogs: [
          ...state.dialogs,
          {
            id: nanoid(),
            params: action.payload,
          },
        ],
      };

    case ActionTypes.CLOSE_DIALOG: {
      const index = state.dialogs.findIndex(
        item => item.id === action.payload.id,
      );

      state.dialogs.splice(index, 1);

      return {
        dialogs: [...state.dialogs],
      };
    }
    default:
      throw new Error('Dialog manager could not find action');
  }
};

export { initialState, ActionTypes };

export default reducer;
