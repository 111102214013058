import { useEffect, useState } from 'react';
import {
  Dropdown,
  Button,
  NavItem,
  NavLink,
  Navbar,
  Nav as NavBootStrap,
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import cx from 'classnames';
import PropTypes from 'prop-types';

import './Nav.scss';

const getEntityLink = (appKey, item) => {
  const view = item.view ? `?view=${item.view}` : '';

  const entityOrCustomPage = item.entityKey
    ? `${item.entityKey}${view}`
    : `pages/${item.page}`;

  return `/apps/${appKey}/${entityOrCustomPage}`;
};

function MobileActionButton({ onClick, children }) {
  return (
    <Button
      variant="primary"
      className="d-flex align-items-center justify-content-between p-3 mx-3 my-2 font-weight-bold"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </Button>
  );
}
MobileActionButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
MobileActionButton.defaultProps = {
  onClick: () => {},
};

const Nav = ({
  apps,
  instanceIcon,
  instanceName,
  isAdmin,
  loading,
  selectedApp,
  menuItems,
  onAppClick,
  onLogoutClick,
  onSettingSchemaClick,
  version,
}) => {
  const [switchAppsMenuMobileOpened, setSwitchAppsMenuMobileOpened] = useState(
    false,
  );
  const [mobileMeuOpened, setMobileMenuOpened] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setMobileMenuOpened(false);
    setSwitchAppsMenuMobileOpened(false);
  }, [location]);

  return (
    <Navbar
      className={cx('main-navbar', { 'bg-light-grey': loading })}
      expand="lg"
    >
      <div className="d-flex">
        <Dropdown as={NavItem} className="d-none d-lg-block">
          <Dropdown.Toggle as={NavLink} className="nav-dropdown app-switcher">
            <i className="fas fa-th" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="app-switcher-dropdown">
            <p className="app-switcher-label">Switch Apps</p>
            {apps?.map(app => (
              <Dropdown.Item
                key={app.id}
                onClick={e => {
                  e.preventDefault();
                  onAppClick(app);
                }}
              >
                <i className={`${app.icon} app-switcher-icon`} />
                {app.name}
              </Dropdown.Item>
            ))}
            {version && (
              <div className="version text-muted mt-2">
                v: {version.client}
                <br />
                c: {version.core}
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <Button
          className="d-block d-lg-none p-0"
          variant="link"
          onClick={() => setMobileMenuOpened(true)}
        >
          <i className="fas fa-bars fa-lg text-white" />
        </Button>
        {selectedApp?.key && (
          <Link
            to={`/apps/${selectedApp?.key}`}
            className="d-flex align-items-center text-decoration-none mr-0 mr-lg-4 selected-app p-3 p-lg-0"
            data-cy="selected-app-desktop"
          >
            <div className="nav-icon d-flex justify-content-center align-items-center text-decoration-none">
              <i
                className={cx('fa-lg', {
                  [selectedApp?.icon]: selectedApp,
                  'fas fa-shipping-fast': !selectedApp,
                })}
              />
            </div>
            <p className="text-white m-0 font-weight-bold ml-3">
              {selectedApp?.name}
            </p>
          </Link>
        )}
      </div>
      {loading || (
        <div className={cx('navbar-items', { opened: mobileMeuOpened })}>
          {switchAppsMenuMobileOpened && (
            <div className="position-absolute w-100 h-100 bg-white z-100 switch-apps-mobile-menu d-block d-lg-none">
              <Button
                variant="link"
                className="d-flex align-items-center text-decoration-none mr-0 mr-lg-4 selected-app px-3 py-4 p-lg-0 w-100"
                onClick={() => setSwitchAppsMenuMobileOpened(false)}
              >
                <i className="fa fa-chevron-left text-dark" />
                <p className="text-white m-0 font-weight-bold ml-3">
                  Switch Apps
                </p>
              </Button>
              <div className="py-1">
                {apps?.map(app => (
                  <Button
                    key={app.id}
                    variant="link"
                    className="d-flex w-100 align-items-center py-2 text-decoration-none"
                    onClick={e => {
                      e.preventDefault();
                      onAppClick(app);
                    }}
                  >
                    <span className="badge badge-primary py-2 px-2 mr-3 d-flex align-items-center justify-content-center d-flex square">
                      <i className={`${app.icon} h5 m-0`} />
                    </span>
                    <span className="text-dark">{app.name}</span>
                  </Button>
                ))}
              </div>
              <div className="d-flex flex-column w-100">
                <MobileActionButton onClick={onSettingSchemaClick}>
                  <span>Settings</span>
                  <i className="fa fa-cog fa-lg" />
                </MobileActionButton>
                <MobileActionButton onClick={onLogoutClick}>
                  <span>Logout</span>
                  <i className="fa fa-sign-out fa-lg" />
                </MobileActionButton>
              </div>
            </div>
          )}
          <Button
            size="sm"
            className="d-flex d-lg-none navbar-items-close-button"
            variant="link"
            onClick={() => setMobileMenuOpened(false)}
          >
            <i className="fa fa-chevron-left text-dark" />
          </Button>
          {selectedApp?.key && (
            <Link
              to={`/apps/${selectedApp?.key}`}
              className="d-flex align-items-center text-decoration-none mr-0 mr-lg-4 selected-app p-3 p-lg-0 d-lg-none"
            >
              <div className="nav-icon d-flex justify-content-center align-items-center text-decoration-none">
                <i
                  className={`${
                    selectedApp ? selectedApp?.icon : 'fas fa-shipping-fast'
                  } fa-lg`}
                />
              </div>
              <p className="text-white m-0 font-weight-bold ml-3">
                {selectedApp?.name}
              </p>
            </Link>
          )}
          {!selectedApp?.name && (
            <div className="border-bottom border-bottom-gray-light d-block d-lg-none">
              <Button
                variant="link"
                className="d-flex align-items-center justify-content-between px-3 py-4 w-100"
                onClick={() => setSwitchAppsMenuMobileOpened(true)}
              >
                <div className="d-flex align-items-center">
                  <i className="fas fa-th fa-lg text-primary mr-2"></i>
                  <span className="text-dark text-decoration-none">
                    Switch Apps
                  </span>
                </div>
                <i className="fa fa-chevron-right text-dark mr-2" />
              </Button>
            </div>
          )}
          <div
            className="d-flex flex-grow-1 align-items-center flex-column flex-lg-row"
            id="basic-navbar-nav"
          >
            <NavBootStrap className="mr-auto align-items-center">
              {menuItems?.map(dropdown => (
                <Dropdown key={dropdown.key} as={NavItem}>
                  {!dropdown.item_groups ? (
                    <Link
                      className="nav-dropdown text-white p-2"
                      to={getEntityLink(selectedApp?.key, dropdown)}
                      data-cy="menu-item"
                    >
                      {dropdown.label}
                    </Link>
                  ) : (
                    <>
                      <Dropdown.Toggle
                        as={NavLink}
                        className="nav-dropdown text-white"
                        data-cy="menu-item-dropdown"
                      >
                        {dropdown.label}
                        <i className="fas fa-chevron-down nav-dropdown-toggle-icon" />
                      </Dropdown.Toggle>

                      <DropdownList
                        appKey={selectedApp?.key}
                        optionsList={dropdown.item_groups}
                      />
                    </>
                  )}
                </Dropdown>
              ))}
            </NavBootStrap>

            <div className="mobile-action-buttons d-flex d-lg-none w-100 flex-column my-n1">
              {selectedApp?.name && (
                <Button
                  variant="primary"
                  className="d-flex align-items-center justify-content-between p-3 mx-3 my-2 font-weight-bold"
                  onClick={() => setSwitchAppsMenuMobileOpened(true)}
                >
                  <span>Switch Apps</span>
                  <i className="fa fa-chevron-right text-dark mr-2" />
                </Button>
              )}
              {isAdmin && (
                <MobileActionButton onClick={onSettingSchemaClick}>
                  <span>Settings</span>
                  <i className="fa fa-cog fa-lg" />
                </MobileActionButton>
              )}
              <MobileActionButton onClick={onLogoutClick}>
                <span>Logout</span>
                <i className="fa fa-sign-out fa-lg" />
              </MobileActionButton>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex align-items-center flex-grow-0 flex-shrink-0 flex-basis-auto">
        {isAdmin && (
          <a
            href="!#"
            className="text-white mr-4 d-none d-lg-block"
            onClick={e => {
              e.preventDefault();
              onSettingSchemaClick();
            }}
          >
            <i className="fa fa-cog fa-lg" />
          </a>
        )}

        <div className="d-flex align-items-center">
          <Button variant="link" className="mr-2 d-block d-lg-none">
            <i className="fas fa-bell fa-lg text-white" />
          </Button>
          <div className="nav-icon p-1 flex-grow-0 flex-shrink-0 flex-basis-auto">
            <img
              className="w-100 h-100 object-fit-contain"
              src={instanceIcon}
              alt={instanceName}
            />
          </div>
        </div>

        <Link
          to="/"
          className="business-name text-white ml-3 d-none d-lg-block flex-grow-0 flex-shrink-0 flex-basis-auto"
        >
          {instanceName}
        </Link>

        <a
          href="!#"
          className="business-name text-white ml-3 d-none d-lg-block"
          onClick={e => {
            e.preventDefault();
            onLogoutClick();
          }}
        >
          Logout
        </a>
      </div>
    </Navbar>
  );
};

Nav.propTypes = {
  apps: PropTypes.array.isRequired,
  instanceIcon: PropTypes.string,
  instanceName: PropTypes.string,
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  menuItems: PropTypes.array,
  onAppClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  onSettingSchemaClick: PropTypes.func.isRequired,
  selectedApp: PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
  }),
  version: PropTypes.shape({
    client: PropTypes.string,
    core: PropTypes.string,
  }),
};

Nav.defaultProps = {
  isAdmin: false,
  menuItems: [],
  version: {},
  instanceIcon: '',
  instanceName: '',
  selectedApp: undefined,
  loading: undefined,
};

export default Nav;

const DropdownList = ({ optionsList, appKey }) => (
  <Dropdown.Menu>
    {optionsList?.length > 0 &&
      optionsList?.map(option =>
        !option.items ? (
          <Dropdown.Item
            bsPrefix="dropdown-container lg"
            as="div"
            key={option.key}
          >
            <Link to={getEntityLink(appKey, option)}>
              <div className="dropdown-subheading ">
                <i className="far fa-file" />
                <p className="dropdown-subheading-label">{option.label}</p>
              </div>
            </Link>
          </Dropdown.Item>
        ) : (
          <div className="dropdown-group" key={option.key}>
            {option.label && (
              <Dropdown.Header className="dropdown-subheading">
                <i className={!option.icon ? 'far fa-file' : option.icon} />
                <p className="dropdown-subheading-label">{option.label}</p>
              </Dropdown.Header>
            )}

            {option.items.map(item => (
              <Dropdown.Item
                bsPrefix="dropdown-container lg"
                as="div"
                key={item.key}
              >
                <Link to={getEntityLink(appKey, item)}>{item.label}</Link>
              </Dropdown.Item>
            ))}
          </div>
        ),
      )}
  </Dropdown.Menu>
);

DropdownList.propTypes = {
  appKey: PropTypes.string,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      labelIcon: PropTypes.string,
      subItems: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};
DropdownList.defaultProps = {
  appKey: '',
};
