import { useState } from 'react';

import { FilterFormValue } from '@april9/stack9-react';
import { FieldItem, WorkflowDefinition } from '@april9/stack9-sdk';
import { post } from 'core/fetch';
import { parseFormFilterDefaultValue } from 'utils/filters';
import { getPropValue } from 'utils/formUtils';

import { useDeepCompareEffect, useDeepCompareMemo } from 'hooks/useDeepCompare';
import { usePrevious } from 'hooks/usePrevious';



export const useQuestringValuesParser = (
  values: FilterFormValue,
  fieldItem: FieldItem,
  workflowDefinition: WorkflowDefinition,
): {
  loading: boolean;
  defaultValue?: FilterFormValue;
} => {
  const pureDefaultValue = useDeepCompareMemo(() => {
    return values
      ? parseFormFilterDefaultValue(values, fieldItem, workflowDefinition)
      : undefined;
  }, [fieldItem, values]);

  const shouldLoadLabels = !!fieldItem.relationshipOptions;

  const [state, setState] = useState({
    initialValue: pureDefaultValue,
    loading: shouldLoadLabels,
  });

  const oldState = usePrevious(state);

  useDeepCompareEffect(() => {
    if (!shouldLoadLabels) {
      return;
    }

    // using had values before and is trying to clear now.
    if (!pureDefaultValue && oldState?.initialValue) {
      setState({ initialValue: undefined, loading: false });
      return;
    }

    const componentOptionValue = fieldItem.ui_component_options?.value || 'id';
    const componentOptionLabel =
      fieldItem.ui_component_options?.label || 'name' || 'id';

    post(`/${fieldItem.relationshipOptions!.related_entity}/query`, {
      $select: [componentOptionLabel, componentOptionValue],
      $where: {
        id: {
          $in: Array.isArray(pureDefaultValue)
            ? pureDefaultValue.map(
                idOrKeyValue =>
                  idOrKeyValue?.value || parseInt(idOrKeyValue, 10),
              )
            : [pureDefaultValue],
        },
      },
    }).then((items: any[]) => {
      setState({
        initialValue: items.map(item => ({
          value: item[componentOptionValue],
          label: getPropValue(componentOptionLabel, item) || '(Blank)',
        })),
        loading: false,
      });
    });
  }, [pureDefaultValue, shouldLoadLabels]);

  if (shouldLoadLabels)
    return { loading: state.loading, defaultValue: state.initialValue };

  return { defaultValue: pureDefaultValue, loading: false };
};
