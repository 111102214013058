import cx from 'classnames';
import PropTypes from 'prop-types';

const TimelineItemRenderer = ({ item, getItemProps }) => (
  <div
    {...getItemProps({
      className: cx({
        'worflow-approved': item.workflowOutcome === 1,
        'worflow-rejected': item.workflowOutcome === 2,
      }),
    })}
  >
    <div className="rct-item-content">
      <span>#{item.id}</span> - {item.title}
    </div>
  </div>
);

TimelineItemRenderer.propTypes = {
  item: PropTypes.object.isRequired,
  getItemProps: PropTypes.func.isRequired,
};

export default TimelineItemRenderer;
