//from: https://stackoverflow.com/questions/54095994/react-useeffect-comparing-objects

import { useEffect, useMemo, useRef } from 'react';

import { isEqual } from 'lodash';

function deepCompareEquals(a: any, b: any) {
  return isEqual(a, b);
}

function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(
  effectCallback: React.EffectCallback,
  deps: React.DependencyList | undefined,
): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCallback, deps?.map(useDeepCompareMemoize));
}

export function useDeepCompareMemo<T>(
  factory: () => T,
  deps: React.DependencyList | undefined,
): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(factory, deps?.map(useDeepCompareMemoize));
}
