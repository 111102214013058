import Modal from 'react-bootstrap/Modal';

import PropTypes from 'prop-types';

const ConfirmationModal = ({
  show,
  title,
  body,
  confirmType,
  confirmAction,
  handleClose,
  modalLevel,
}) => (
  <Modal
    show={show}
    onHide={handleClose}
    backdropClassName={`modal-level-${modalLevel}`}
    className={`modal-level-${modalLevel}`}
  >
    <Modal.Header>
      <Modal.Title>
        <h5>{title}</h5>
      </Modal.Title>
      <div
        className="toggle-icon"
        onClick={handleClose}
        onKeyDown={handleClose}
        role="button"
        tabIndex={-1}
        data-cy="modal-close"
      >
        <i className="fas fa-times" />
      </div>
    </Modal.Header>

    <Modal.Body className="p-4">{body}</Modal.Body>

    <Modal.Footer className="justify-content-start">
      <button
        className={`btn btn-${confirmType}`}
        type="button"
        onClick={confirmAction}
        data-cy="modal-confirm"
      >
        Confirm
      </button>
    </Modal.Footer>
  </Modal>
);

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string.isRequired,
  confirmType: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  modalLevel: PropTypes.number,
};

ConfirmationModal.defaultProps = {
  show: false,
  title: 'Confirmation',
  confirmType: 'primary',
  handleClose: () => {},
  modalLevel: 50,
};

export default ConfirmationModal;
