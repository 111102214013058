import { useContext, useEffect } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';

import {
  EntityDefinition,
  FormLayout,
  FormLayoutWithWorkflow,
  GridSettings,
  Hooks,
  PrintableDocs,
  RelatedEntityFields,
  VisualWorkflow,
} from 'components/Schema';
import { SchemaContext } from 'contexts/SchemaContext';

import LayoutWithMenu from '../components/Layout/LayoutWithMenu';

const SchemaEdit = () => {
  const { entityType } = useParams<{ entityType: string }>();
  const { fetchEntityJsonSchema, state } = useContext(SchemaContext);

  useEffect(() => {
    fetchEntityJsonSchema(entityType);
  }, [entityType, fetchEntityJsonSchema]);

  const schema = state.entityJson;

  if (!schema) {
    return (
      <LayoutWithMenu>
        <Container />
      </LayoutWithMenu>
    );
  }

  const restApiEndpoint = `${window.location.origin}/api/api-docs/#/${entityType}`;

  return (
    <LayoutWithMenu>
      <Container>
        <Row className="mb-4">
          <Col>
            <h1>
              <Link to="/settings/schemas">Entity:</Link> {schema.name}
            </h1>
          </Col>
        </Row>

        <Tab.Container id="schema-tabs" defaultActiveKey="entity-definition">
          <Nav variant="tabs" className="mb-4">
            <Nav.Item>
              <Nav.Link eventKey="entity-definition">
                Entity Definition
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="form-layout">
                Form Layout {schema.hasWorkflow ? ' with Workflow' : ''}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="workflow-and-automation">
                Workflow and Automation
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="data-table">Data Table</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="printable-docs">Printable Documents</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="entity-definition" mountOnEnter unmountOnExit>
              <h5>Fields List</h5>
              <EntityDefinition schema={schema} />

              <h5 className="mt-4">Related Entities</h5>
              <RelatedEntityFields fields={schema.fields} />

              <h5 className="mt-4">REST API (Developer)</h5>
              <p>
                Use the web address below to provide interoperability between
                computer systems.
                <br />
                <a href={restApiEndpoint} target="blank">
                  {restApiEndpoint}
                </a>
              </p>
            </Tab.Pane>

            <Tab.Pane eventKey="form-layout" mountOnEnter unmountOnExit>
              {schema.hasWorkflow ? (
                <>
                  <FormLayoutWithWorkflow schema={schema} />
                </>
              ) : (
                <>
                  <h5>Fields</h5>
                  <FormLayout
                    formLayout={schema.formLayout}
                    fields={schema.fields}
                  />
                </>
              )}
            </Tab.Pane>

            <Tab.Pane
              eventKey="workflow-and-automation"
              mountOnEnter
              unmountOnExit
            >
              <h5>Associated Workflow</h5>
              {schema.workflow.definition ? (
                <VisualWorkflow workflow={schema.workflow} />
              ) : (
                'Entity has no associated workflow'
              )}

              <h5 className="mt-4">Automation (Hooks)</h5>

              {schema.hooks.length > 0 ? (
                <Hooks hooks={schema.hooks} />
              ) : (
                <span>
                  There are no automation workflows defined for this entity.
                </span>
              )}
            </Tab.Pane>

            <Tab.Pane eventKey="data-table" mountOnEnter>
              <h5>Data Table</h5>
              <GridSettings rowData={schema.gridSettings?.agGrid?.columnDefs} />

              <h5 className="mt-4">Export Settings</h5>
              <GridSettings
                rowData={
                  schema.exportSettings?.columnDefs ||
                  schema.gridSettings?.agGrid?.columnDefs
                }
              />
            </Tab.Pane>

            <Tab.Pane eventKey="printable-docs" mountOnEnter>
              {schema.printableDocuments.length > 0 ? (
                <PrintableDocs printableDocuments={schema.printableDocuments} />
              ) : (
                <span>
                  There are no printable documents defined for this entity.
                </span>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </LayoutWithMenu>
  );
};
export default SchemaEdit;
