// WARNING:  this file sadly is duplicated server-side, check dateUtils.ts

import moment from 'moment';

const normaliseFormat = (
  dateFormat,
  timeFormat = undefined,
  options = { moment: true },
) => {
  if (options.moment) {
    return `${dateFormat.replace('dd', 'DD').replace('yyyy', 'YYYY')}${
      timeFormat ? ` ${timeFormat}` : ''
    }`;
  }

  return `${dateFormat}${timeFormat ? ` ${timeFormat}` : ''}`;
};

const formatDateToString = (appSettings, dateValue, time) => {
  if (!dateValue) {
    return '';
  }

  return time
    ? moment(dateValue)
        .local()
        .format(normaliseFormat(appSettings.dateFormat, appSettings.timeFormat))
    : moment.utc(dateValue).format(normaliseFormat(appSettings.dateFormat));
};

export { normaliseFormat, formatDateToString };
