const Messages = {
  EntityCreated: 'Record created',
  EntityDeleted: 'Record deleted',
  EntityUpdated: 'Record updated',
  EntitySaved: 'Record saved',
  CommentAdded: 'Your comment has been made',
  TaskAdded: 'Your task has been added',
  TaskDeleted: 'Task has been deleted',
  AttachmentInserted: 'Attachment added',
  AttachmentDeleted: 'Attachment has been deleted',
};

export { Messages };
