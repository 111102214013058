function downloadFile(fileName, content) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(content);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(link.href);
  link.remove();
}

export default downloadFile;
