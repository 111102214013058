import ContentLoader from 'react-content-loader';

import PropTypes from 'prop-types';

const DashboardLoader = ({ height, width, ...props }) => {
  const colWidth = (width || 900) / 3;
  const colHeight1 = (width || 450) * 0.3111111111111111;
  const colHeight2 = (width || 450) * 0.6222222222222222;

  return (
    <ContentLoader
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <rect x="10" y="10" rx="5" ry="5" width={colWidth} height={colHeight1} />
      <rect
        x={colWidth + 20}
        y="10"
        rx="5"
        ry="5"
        width={colWidth}
        height={colHeight2}
      />
      <rect
        x={colWidth * 2 + 30}
        y="10"
        rx="5"
        ry="5"
        width={colWidth}
        height={colHeight1}
      />
      <rect
        x="10"
        y={colHeight1 + 20}
        rx="5"
        ry="5"
        width={colWidth}
        height={colHeight2}
      />
      <rect
        x={colWidth + 20}
        y={colHeight1 * 2 + 20}
        rx="5"
        ry="5"
        width={colWidth}
        height={colHeight1}
      />
      <rect
        x={colWidth * 2 + 30}
        y={colHeight1 + 20}
        rx="5"
        ry="5"
        width={colWidth}
        height={colHeight2}
      />
    </ContentLoader>

    /* <ContentLoader
viewBox={`0 0 ${width} ${height}`}
height={height}
{...props}
>
<rect x="10" y="10" rx="5" ry="5" width="100%" height="140" />
<rect x="280" y="10" rx="5" ry="5" width="100%" height="280" />
<rect x="550" y="10" rx="5" ry="5" width="100%" height="140" />
<rect x="10" y="160" rx="5" ry="5" width="100%" height="280" />
<rect x="280" y="300" rx="5" ry="5" width="100%" height="140" />
<rect x="550" y="160" rx="5" ry="5" width="100%" height="280" />
</ContentLoader> */
  );
};

DashboardLoader.propTypes = {
  speed: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
};

DashboardLoader.defaultProps = {
  speed: 2,
  width: 1008,
  height: 700,
  backgroundColor: '#f3f3f3',
  foregroundColor: '#ecebeb',
};

export default DashboardLoader;
