import {
  Fragment,
  useState,
  memo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FilterFormKeyField, FilterFormKeyValue } from '@april9/stack9-react';
import { WorkflowDefinition, FilterFieldsSchema } from '@april9/stack9-sdk';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';

import { UIComponents } from 'constants/fieldTypes';
import { useDeepCompareMemo } from 'hooks/useDeepCompare';

import AppliedFilter from './AppliedFilter';
import FilterPopover from './FilterPopover';

import s from './Filters.module.scss';

const getDefaultFilters = (
  filterFields: FilterFieldsSchema,
  filterFormValues: FilterFormKeyValue,
) => {
  const filtersAppliedFromQuerystring = Object.keys(filterFormValues || {});

  if (filtersAppliedFromQuerystring.length) {
    return filtersAppliedFromQuerystring;
  }

  return Object.values(filterFields)
    .filter(
      (filter: any) => filter.ui_component_options?.displayAsDefaultFilter,
    )
    .map((filter: any) => filter.key);
};

interface Props {
  filterFields: FilterFieldsSchema;
  filterFormValues: FilterFormKeyValue;
  workflowDefinition: WorkflowDefinition;
  submitFilter: (data: FilterFormKeyField) => void;
}

const Filters = ({
  filterFields,
  workflowDefinition,
  submitFilter,
  filterFormValues,
}: Props) => {
  const defaultFilters = useDeepCompareMemo(
    () => getDefaultFilters(filterFields, filterFormValues),
    [filterFields, filterFormValues],
  );

  // selected filters = Checkboxes items inside of add filter dropdown
  const [selectedFilters, setSelectedFilters] = useState<Array<string>>([
    ...defaultFilters,
  ]);
  // applied filters = Filters that are applied and being displayed
  // at the top of the view
  const [appliedFilters, setAppliedFilters] = useState<Array<string>>([
    ...defaultFilters,
  ]);

  useEffect(() => {
    setSelectedFilters([...defaultFilters]);
    setAppliedFilters([...defaultFilters]);
  }, [defaultFilters]);

  const [keyword, setKeyword] = useState('');
  const addFiltersSearchField = useRef<HTMLInputElement>(null);

  const fields = useDeepCompareMemo(
    () =>
      Object.keys(filterFields)
        .reduce(
          (current: Array<any>, keyName: string) => [
            ...current,
            filterFields[keyName],
          ],
          [],
        )
        .filter(field =>
          field.label.toLowerCase().includes(keyword.toLowerCase()),
        ),
    [filterFields, keyword],
  );

  const formMethods = useForm();

  /**
   * This function will set all 'checked' selectedFilters(checkboxes)
   * as appliedFilters
   */
  const applyFilters = useCallback(() => {
    setAppliedFilters([...selectedFilters]);
  }, [selectedFilters]);

  /**
   * This function will reset the filterkey value
   * @param {string} filterKey
   */
  const resetValue = useCallback(
    (filterKey: string) => {
      switch (filterFields[filterKey].ui_component) {
        case UIComponents.DateField: {
          formMethods.setValue(`${filterKey}.value[0]`, '');
          formMethods.setValue(`${filterKey}.value[1]`, '');
          break;
        }
        default: {
          formMethods.setValue(`${filterKey}.value`, '');
          break;
        }
      }
    },
    [filterFields, formMethods],
  );

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit(submitFilter)();
  }, [formMethods, submitFilter]);

  /**
   * This function will remove the applied filter
   * AppliedFilter(dropdown) and selectedFilter(checkbox) should be reset
   * @param {string} filterKey
   */
  const removeAppliedFilter = useCallback(
    (filterKey: string) => {
      /* TODO: this is not going through the flow, so there are 2 things happening at the same time
         1 - updating the state for this component
         2 - changing the URL which will also rebuild this component
         bug: back button might not work correctly.
         fix: find a way to rebuild the applied/selected filters from the URL keys.
      */
      resetValue(filterKey);
      setAppliedFilters([...appliedFilters.filter(item => item !== filterKey)]);
      setSelectedFilters([
        ...selectedFilters.filter(item => item !== filterKey),
      ]);

      formMethods.handleSubmit(data => {
        delete data[filterKey];
        submitFilter(data);
      })();
    },
    [appliedFilters, formMethods, resetValue, selectedFilters, submitFilter],
  );

  return (
    <div className={s.root}>
      <FilterPopover
        id="add-filters"
        onApply={applyFilters}
        showDrop
        enableHorizontalScroll={fields.length > 0}
        onOpen={() => {
          if (!addFiltersSearchField.current) return;
          addFiltersSearchField.current.focus();
        }}
        onClose={() => {
          setKeyword('');
        }}
        title={
          <span data-cy="filter-toggle">
            <i className="fas fa-filter mr-2" />
            <span>Add Filters</span>
          </span>
        }
      >
        <div data-cy="filter-fields-dropdown">
          <input
            ref={addFiltersSearchField}
            type="text"
            className="form-control mb-2"
            placeholder="Search field..."
            data-cy="filter-fields-search"
            onChange={e => setKeyword(e.target.value)}
            value={keyword}
          />

          <label className={s.subtitle}>Pick a field</label>

          {fields.length > 0 ? (
            orderBy(fields, 'label', 'asc').map(field => (
              <div key={field.key}>
                <input
                  name={`filter-${field.key}`}
                  checked={selectedFilters.includes(field.key)}
                  id={`filter-${field.key}`}
                  type="checkbox"
                  onChange={e => {
                    const values = selectedFilters;
                    const idx = values.indexOf(e.target.value);

                    if (idx !== -1) {
                      values.splice(idx, 1);
                    } else {
                      values.push(e.target.value);
                    }

                    setSelectedFilters([...values]);
                  }}
                  value={field.key}
                />{' '}
                <label htmlFor={`filter-${field.key}`}>{field.label}</label>
              </div>
            ))
          ) : (
            <div>
              No field match{' '}
              <i>
                <strong>"{keyword}"</strong>
              </i>
            </div>
          )}
        </div>
      </FilterPopover>

      {appliedFilters.length > 0 && (
        <FormProvider {...formMethods}>
          <form
            className={s.form}
            onSubmit={handleSubmit}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            {appliedFilters.map(fieldKey => (
              <Fragment key={fieldKey}>
                {filterFields[fieldKey] && (
                  <AppliedFilter
                    filterKey={fieldKey}
                    values={filterFormValues[fieldKey]} // just labels.
                    fieldItem={filterFields[fieldKey]}
                    workflowDefinition={workflowDefinition}
                    removeAppliedFilter={removeAppliedFilter}
                    handleSubmit={handleSubmit}
                  />
                )}
              </Fragment>
            ))}
          </form>
        </FormProvider>
      )}

      {/* TODO: Add ID to this list of filter items */}
    </div>
  );
};

Filters.propTypes = {
  filterFields: PropTypes.object.isRequired,
  workflowDefinition: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  submitFilter: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  workflowDefinition: false,
  filterFormValues: {},
};

export default memo(Filters);
