import { ReactNode, useEffect, useState } from 'react';

import cx from 'classnames';

import Popover from 'components/Popover/Popover';

import s from './FilterPopover.module.scss';

type Props = {
  id: string;
  children: ReactNode;
  className?: string;
  onApply?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  showDrop?: boolean;
  enableHorizontalScroll?: boolean;
  title: ReactNode;
};

const FilterPopover = ({
  id,
  children,
  className = '',
  onApply = () => {},
  onOpen = () => {},
  onClose = () => {},
  showDrop = false,
  enableHorizontalScroll = false,
  title,
}: Props) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      return onClose();
    }

    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const apply = () => {
    if (typeof onApply === 'function') {
      onApply();
    }

    setIsActive(false);
  };

  return (
    <Popover
      id={id}
      toggle={title}
      className={className}
      isActive={isActive}
      setIsActive={setIsActive}
      showDrop={showDrop}
    >
      <div
        className={cx(s.fields, {
          [s.horizontalScroll]: enableHorizontalScroll,
        })}
        data-cy="apply-filter-fields"
      >
        {children}
      </div>

      <div className={s.apply}>
        <button
          type="button"
          className="btn btn-primary btn-sm font-weight-bolder mr-3"
          data-cy={`apply-${id}-btn`}
          onKeyDown={e => {
            if (e.key !== 'Enter') return;

            apply();
          }}
          onClick={apply}
        >
          Apply
        </button>
      </div>
    </Popover>
  );
};

export default FilterPopover;
