import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const AppliedFilterLoader = ({ ...props }: IContentLoaderProps) => (
  <ContentLoader
    width="200"
    height="31"
    viewBox="0 0 200 31"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect rx="3" width="200" height="31" />
  </ContentLoader>
);

export default AppliedFilterLoader;
