import { FieldItem } from '@april9/stack9-sdk';
import Joi from 'joi';
import { each, isObject, uniq } from 'lodash';

// do we want to have custom messages hardcoded?
// const errorMessages = rule => {
//   const messages = {
//     required: 'This field is required.',
//     maxLength: `Max Lenght for this field is ${rule[1]}.`,
//     minLength: `Min Lenght for this field is ${rule[1]}.`,
//     max: `Max Number allowed is ${rule[1]}`,
//     min: `Min Number allowed is ${rule[1]}`,
//     pattern: `The allowed pattern for this field is ${rule[1]}`,
//   };

//   return messages[rule[0]];
// };

const getPropertyRecursive = (obj, property) => {
  let values: Array<any> = [];
  each(obj, (value, key) => {
    if (key === property) {
      values.push(value);
    } else if (isObject(value)) {
      values = values.concat(getPropertyRecursive(value, property));
    }
  });

  return values;
};

const buildSchemaValidation = (
  entitySchema,
  options = { ignoreRequired: false },
) => {
  const fieldsInTheForm = entitySchema.formLayout.fieldsets.reduce(
    (prev, fieldset) => {
      const fieldKeys = getPropertyRecursive(fieldset, 'fieldKey');

      return uniq([...prev, ...fieldKeys]);
    },
    [],
  );

  const schemaValidation = Object.keys(entitySchema.fields)
    .filter(itemKey => fieldsInTheForm.indexOf(itemKey) !== -1)
    .reduce((prevValidation, fieldKey) => {
      const field = entitySchema.fields[fieldKey] as FieldItem;
      let joi: any = Joi;

      switch (field.ui_component) {
        case 'Checkbox':
          joi = joi.boolean();
          break;
        case 'NumericField': {
          const precision = field.ui_component_options?.precision;

          if (precision) {
            joi = joi.number().precision(precision);
          } else {
            joi = joi.number().integer();
          }

          // 9999999999999 = maxValue for db numeric(15) validation rules can override this, keep it first.
          joi = joi.max(9999999999999);
          break;
        }
        case 'MultiDropDown':
          joi = Joi.array().items(
            Joi.object({
              label: Joi.any().optional(),
              value: Joi.number(),
            }),
          );
          break;

        case 'DateField':
          joi = joi.date();
          break;
        case 'MonacoEditorField':
          {
            const { language } = field.ui_component_options!;

            if (language === 'json') {
              joi = Joi.custom(value => {
                try {
                  JSON.parse(value);
                  return value;
                } catch (e) {
                  throw new Error('json format is invalid');
                }
              });
            } else {
              joi = Joi.string();
            }
          }

          break;
        case 'SingleDropDown':
          joi = Joi.object({
            label: Joi.any().optional(),
            value: Joi.number(),
          });
          break;
        case 'Grid':
          joi = Joi.any();
          break;
        case 'PrivilegiesMatrix':
          joi = Joi.object();
          break;
        case 'OptionSet':
          joi = Joi.object({
            label: Joi.string(),
            value: Joi.string(),
          });
          break;
        default:
          joi = joi.string();
      }

      joi = Object.keys(field.validation_rules).reduce((currJoi, currKey) => {
        const evalRule = field.validation_rules[currKey];
        switch (currKey) {
          case 'required':
            return evalRule && !options.ignoreRequired
              ? currJoi.required()
              : currJoi.allow('').allow(null).optional();
          case 'min':
          case 'minLength':
            return currJoi.min(evalRule);
          case 'max':
          case 'maxLength':
            return currJoi.max(evalRule);
          case 'pattern':
            return currJoi.pattern(RegExp(evalRule));
          default:
            throw new Error(
              `Validation type '${currKey}' not handled by the validator`,
            );
        }
      }, joi);

      return {
        ...prevValidation,
        [fieldKey]: joi,
      };
    }, {});

  return Joi.object(schemaValidation);
};

export { buildSchemaValidation, getPropertyRecursive };
