import { Helmet } from 'react-helmet';

import PropTypes from 'prop-types';
import { capitalize } from 'utils/text';

const MetaTags = ({ appKey, entityName }) => (
  <Helmet>
    <title>
      {capitalize(appKey)} - {entityName} | Stack9
    </title>
  </Helmet>
);

MetaTags.propTypes = {
  appKey: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
};

export default MetaTags;
