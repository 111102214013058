const VIEWS = Object.freeze({
  LIST: 'listView',
  MAP: 'mapView',
  KANBAN: 'kanbanBoardView',
  TIMELINE: 'timelineView',
});

const VIEWS_ICONS = {
  [VIEWS.LIST]: <i className="fas fa-list" />,
  [VIEWS.MAP]: <i className="fas fa-map-marker-alt" />,
  [VIEWS.KANBAN]: <i className="fas fa-th" />,
  [VIEWS.TIMELINE]: <i className="fas fa-calendar-alt" />,
};

export default { VIEWS, VIEWS_ICONS };
