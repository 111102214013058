import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import CustomUIPage from 'routes/CustomUIPage';

import HtmlEditor from 'components/HtmlEditor/HtmlEditor';
import { useAuthContext } from 'contexts/AuthContext';
import SchemaContextProvider from 'contexts/SchemaContext';

import LayoutWithMenu from './components/Layout/LayoutWithMenu';
import AppDashboard from './routes/AppDashboard';
import Dashboard from './routes/Dashboard';
import Entity from './routes/Entity/Entity';
import Login from './routes/Login/Login';
import Report from './routes/Report/Report';
import SchemaEdit from './routes/SchemaEdit';
import SchemaList from './routes/SchemaList';

export default function Routes() {
  return (
    <Switch>
      <Route path="/auth/login" exact>
        <Login />
      </Route>

      <ProtectedRoute path="/" exact>
        <Dashboard />
      </ProtectedRoute>

      <Route path="/html-editor" exact>
        <HtmlEditor />
      </Route>

      <ProtectedRoute path="/apps/report" exact>
        <Report />
      </ProtectedRoute>

      <ProtectedRoute path="/apps/:appKey" exact>
        <AppDashboard />
      </ProtectedRoute>

      <ProtectedRoute path="/apps/:appKey/pages/:pageComponent" exact>
        <CustomUIPage />
      </ProtectedRoute>

      <ProtectedRoute path="/apps/:appKey/:entityKey/:operation?/:id?" exact>
        <Entity />
      </ProtectedRoute>

      {/* <ProtectedRoute path="/apps/project" exact>
          <EntityList />
        </ProtectedRoute>

        <ProtectedRoute path="/apps/project/edit" exact>
          <EntityDetail />
        </ProtectedRoute> */}

      <SchemaContextProvider>
        <ProtectedRoute path="/settings/schemas" exact>
          <SchemaList />
        </ProtectedRoute>

        <ProtectedRoute path="/settings/schemas/:entityType" exact>
          <SchemaEdit />
        </ProtectedRoute>
      </SchemaContextProvider>

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

function RedirectToAuth() {
  return <Redirect to="/auth/login" />;
}

function ProtectedRoute({ children, ...props }) {
  const { state } = useAuthContext();

  return (
    <Route {...props}>
      {!state.authenticatedUser ? <RedirectToAuth /> : children}
    </Route>
  );
}

function NotFound() {
  const location = useLocation();

  return (
    <LayoutWithMenu>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </LayoutWithMenu>
  );
}

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
