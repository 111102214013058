import PropTypes from 'prop-types';

import { AgGrid } from 'components/ThirdLibraries';

import s from './EntityDefinition.module.scss';

const EntityDefinition = ({ schema }) => {
  const fieldsArray = Object.keys(schema.fields).map(
    fieldKey => schema.fields[fieldKey],
  );

  const columnDefs = [
    {
      field: 'key',
      sortable: true,
    },
    {
      field: 'label',
      sortable: true,
    },
    {
      headerName: 'Type',
      field: 'ui_component',
      sortable: true,
    },
    {
      headerName: 'Validation',
      field: 'validation_rules',
      sortable: true,
      cellRenderer: row => `<div>
              ${
                row.value.required
                  ? `<span class="badge badge-danger">REQUIRED</span>`
                  : ''
              }
              ${
                row.value.pattern
                  ? `<span class="badge badge-secondary">${row.value.pattern}</span>`
                  : ''
              }
            </div>`,
    },
  ];

  return (
    <AgGrid
      enableCellTextSelection
      className={s.grid}
      rowData={fieldsArray}
      columnDefs={columnDefs}
    />
  );
};

EntityDefinition.propTypes = {
  schema: PropTypes.object.isRequired,
};

export default EntityDefinition;
