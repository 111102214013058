import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import PropTypes from 'prop-types';

import FieldLabel from './FieldLabel';

const TextField = ({ name, defaultValue, fieldItem }) => {
  const { register, errors } = useFormContext();
  const placeholder = fieldItem?.placeholder;
  const multiLine = fieldItem.ui_component_options?.multiLine;
  const readOnly = fieldItem.ui_component_options?.readOnly || false;
  const isText = fieldItem.ui_component_options?.text || false;
  const inputProps = {
    name,
    className: cx('form-control', { 'is-invalid': errors[name] }),
    placeholder,
    id: name,
    readOnly,
    ref: register(),
    defaultValue,
  };

  const maxLength = !isText ? { maxLength: 255 } : {};

  return (
    <FieldLabel fieldItem={fieldItem}>
      {!multiLine ? (
        <input type="text" {...inputProps} />
      ) : (
        <textarea rows={4} {...inputProps} {...maxLength} />
      )}
      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}
    </FieldLabel>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.shape({
    ui_component_options: PropTypes.shape({
      multiLine: PropTypes.bool,
      readOnly: PropTypes.bool,
      text: PropTypes.bool,
    }),
    placeholder: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

TextField.defaultProps = {
  defaultValue: '',
};

export default TextField;
