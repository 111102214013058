import cx from 'classnames';
import PropTypes from 'prop-types';

import s from './FieldLabel.module.scss';

const FieldLabel = ({ fieldItem, children, actions }) => (
  <div className="form-group">
    <div
      className={cx(
        'd-flex justify-content-between align-items-baseline align-middle',
        s.items,
      )}
    >
      <label htmlFor={fieldItem.key}>
        {`${fieldItem.label}:`}
        <span className="text-danger">
          {fieldItem.validation_rules?.required &&
            !fieldItem.ui_component_options?.readOnly &&
            ' *'}
        </span>
      </label>

      {actions}
    </div>
    {children}
  </div>
);

FieldLabel.propTypes = {
  fieldItem: PropTypes.shape({
    validation_rules: PropTypes.any,
    ui_component: PropTypes.string,
    ui_component_options: PropTypes.any,
    label: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

FieldLabel.defaultProps = {
  actions: undefined,
};

export default FieldLabel;
