import React, { createContext, useReducer, useContext } from 'react';

import { post } from 'core/fetch';
import PropTypes from 'prop-types';

import reducer, { initialState, AuthActions } from './state/AuthState';

interface AuthContextActions {
  state: any;
  logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextActions>({
  state: undefined,
  logout: () => Promise.reject(),
});

const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  /*
   * ACTIONS
   */
  const logout = async () => {
    dispatch({ type: AuthActions.Logout });

    try {
      await post(`/auth/logout`, {});
      dispatch({ type: AuthActions.LogoutSuccess });
      window.location.href = '/';
    } catch (e) {
      dispatch({ type: AuthActions.LogoutFail });
    }
  };

  const actions = { logout };

  return (
    <AuthContext.Provider
      value={{
        ...actions,
        state,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { useAuthContext };
export default AuthContextProvider;
