import { IJsonEntityView } from '@april9/stack9-sdk';

import ENTITY from 'constants/Entity';

const getPropValue = (propPathOrName, obj) => {
  const names = propPathOrName.split('.');
  return names.reduce((prev, currKey) => {
    if (!prev) return undefined;

    return prev[currKey];
  }, obj);
};

const getViewGroupBy = (view: IJsonEntityView): Array<string> => {
  if (!view || !view.viewOptions?.groupBy) {
    return [];
  }

  const groupBy: Array<string> = [];

  const { sourceType, sourceField } = view.viewOptions.groupBy;

  if (sourceType === 'workflow') {
    groupBy.push('_workflow_current_step');
  }

  if (sourceField) {
    groupBy.push(sourceField);
  }

  return groupBy;
};

const getDefaultView = (
  views: IJsonEntityView[] | undefined,
  defaultName: string,
  currState?: any,
) => {
  if (currState?.view) return currState?.view;

  if (!views?.length) {
    return {
      name: defaultName || 'Default list',
      viewType: ENTITY.VIEWS.LIST,
    };
  }

  const view = views[0];

  const groupBy: Array<string> = [];
  if (view?.viewOptions?.groupBy) {
    const { sourceType, sourceField } = view?.viewOptions?.groupBy || {};

    if (sourceType === 'workflow') {
      groupBy.push('_workflow_current_step');
    }

    if (sourceField) {
      groupBy.push(sourceField);
    }
  }

  return view;
};

const getStateName = (entityKey, entityId) => {
  if (!entityKey) throw new Error('Reducer requires entityKey');

  if (!entityId) return entityKey;

  return `${entityKey}_${entityId}`;
};

export { getPropValue, getDefaultView, getViewGroupBy, getStateName };
