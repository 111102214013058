import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

import { post } from 'core/fetch';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';
import { getPropValue } from 'utils/formUtils';

const MultiDropDown = ({
  name,
  options: propOptions,
  defaultValue,
  uiComponentOptions,
  relationshipOptions,
  limit,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { control, errors } = useFormContext();

  const componentOptionValue = uiComponentOptions?.value || 'id';
  const componentOptionLabel = uiComponentOptions?.label || 'name' || 'id';

  const fetchRelatedOptions = async (
    searchText?: string,
    fieldToQuery?: string,
  ) => {
    if (!relationshipOptions) return;

    setLoading(true);

    const filterWhere = relationshipOptions.filter_where;

    const items = await post(
      `/${relationshipOptions.related_entity}/search?limit=${limit}`,
      {
        $where: {
          ...(filterWhere ?? {}),
          ...(searchText && fieldToQuery
            ? { [fieldToQuery]: { $like: `%${searchText}%` } }
            : {}),
          _is_deleted: false,
        },
      },
    );

    setOptions(
      items.map(item => ({
        value: item[componentOptionValue],
        label: getPropValue(componentOptionLabel, item) || '(Blank)',
      })),
    );
    setLoading(false);
  };

  useEffect(() => {
    if (propOptions) {
      setOptions(propOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propOptions]);

  const [inputText, setInputText] = useState();
  const [searchText] = useDebounce(inputText, 500);

  useEffect(() => {
    if (searchText || searchText === '') {
      fetchRelatedOptions(searchText, componentOptionLabel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleInputChange = inputValue => {
    setInputText(inputValue);
  };

  return (
    <Controller
      as={ReactSelect}
      styles={{
        control: provided => ({
          ...provided,
          borderColor: errors[name] ? 'red' : '#ced4da',
          minHeight: '3rem',
        }),
        menuPortal: base => ({ ...base, zIndex: 99999 }),
      }}
      onInputChange={handleInputChange}
      onMenuOpen={() => {
        if (!inputText) {
          fetchRelatedOptions();
        }
      }}
      menuPortalTarget={document.body}
      noOptionsMessage={() => (loading ? 'Loading...' : 'No Options')}
      control={control}
      options={options}
      defaultValue={defaultValue}
      name={name}
      id={name}
      isMulti
    />
  );
};

MultiDropDown.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  limit: PropTypes.number,
  uiComponentOptions: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  relationshipOptions: PropTypes.shape({
    related_entity: PropTypes.string.isRequired,
    filter_where: PropTypes.object,
  }),
};

MultiDropDown.defaultProps = {
  options: [],
  defaultValue: [],
  limit: 30,
  uiComponentOptions: undefined,
  relationshipOptions: undefined,
};

export default MultiDropDown;
