import { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';

import { post } from 'core/fetch';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';
import { getPropValue } from 'utils/formUtils';

import { useDialogManager } from 'contexts/DialogManagerContext';

import FieldLabel from './FieldLabel';

const MultiDropDown = ({
  name,
  options: propOptions,
  defaultValue,
  fieldItem,
  limit,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { control, errors } = useFormContext();
  const { openDialog } = useDialogManager();

  const componentOptionValue = fieldItem.ui_component_options?.value;
  const componentOptionLabel =
    fieldItem.ui_component_options?.label || 'name' || 'id';
  const placeholder = fieldItem?.placeholder;
  const filterWhere = fieldItem.relationshipOptions?.filter_where;
  const fetchRelatedOptions = async (
    searchText?: string,
    fieldToQuery?: string,
  ) => {
    if (!fieldItem.relationshipOptions) return;
    setLoading(true);

    try {
      const items = await post(
        `/${fieldItem.relationshipOptions.related_entity}/search?limit=${limit}`,
        {
          $where: {
            ...(filterWhere ?? {}),
            ...(searchText && fieldToQuery
              ? { [fieldToQuery]: { $like: `%${searchText}%` } }
              : {}),
            _is_deleted: false,
          },
        },
      );

      setOptions(
        items.map(item => ({
          value: item[componentOptionValue],
          label: getPropValue(componentOptionLabel, item) || '(Blank)',
        })),
      );
      setLoading(false);
    } catch (err) {
      throw new Error(err);
    }
  };

  useEffect(() => {
    if (propOptions) {
      setOptions(
        propOptions.map(prop => ({
          label: getPropValue(componentOptionLabel, prop),
          value: prop[componentOptionValue],
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformedDefaultValue =
    defaultValue && defaultValue.length
      ? defaultValue.map(prop => ({
          value: prop[componentOptionValue],
          label: getPropValue(componentOptionLabel, prop),
        }))
      : undefined;

  const [inputText, setInputText] = useState();
  const [searchText] = useDebounce(inputText, 500);

  useEffect(() => {
    if (searchText || searchText === '') {
      fetchRelatedOptions(searchText, componentOptionLabel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleInputChange = inputValue => {
    setInputText(inputValue);
  };

  return (
    <FieldLabel
      fieldItem={fieldItem}
      actions={
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={() => {
            openDialog({
              entityKey: fieldItem.relationshipOptions?.related_entity,
            });
          }}
        >
          Create new
        </button>
      }
    >
      <Controller
        as={ReactSelect}
        styles={{
          control: provided => ({
            ...provided,
            borderColor: errors[name] ? 'red' : '#ced4da',
            minHeight: '3rem',
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
        onInputChange={handleInputChange}
        onMenuOpen={() => {
          if (!inputText) {
            fetchRelatedOptions();
          }
        }}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        noOptionsMessage={() => (loading ? 'Loading...' : 'No Options')}
        control={control}
        options={options}
        defaultValue={transformedDefaultValue}
        name={name}
        id={name}
        closeMenuOnSelect={false}
        isMulti
      />

      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}
    </FieldLabel>
  );
};

MultiDropDown.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  limit: PropTypes.number,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    relationshipOptions: PropTypes.shape({
      related_entity: PropTypes.string.isRequired,
      filter_where: PropTypes.object,
    }),
  }).isRequired,
};

MultiDropDown.defaultProps = {
  options: [],
  defaultValue: [],
  limit: 30,
};

export default MultiDropDown;
