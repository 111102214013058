import { useContext } from 'react';
import { Col, Row, Card, Container, Button } from 'react-bootstrap';

import LayoutWithMenu from '../components/Layout/LayoutWithMenu';
import { AppContext } from '../contexts/AppContext';

const Dashboard = () => {
  const { state } = useContext(AppContext);
  return (
    <LayoutWithMenu>
      <Container className="Dashboard">
        <div className="mx-auto" style={{ maxWidth: '620px' }}>
          <h3 className="text-primary font-weight-bold mb-4">Apps</h3>
          <Row className="d-flex">
            {state.apps.map(app => (
              <Col md={4} key={app.key} className="mb-3">
                <Button
                  as="a"
                  variant="link"
                  className="w-100 p-0 text-decoration-none"
                  href={`/apps/${app.key}`}
                >
                  <Card key={app.key}>
                    <Card.Body className="d-flex flex-column position-relative">
                      <div className="d-flex justify-content-between">
                        <Card className="bg-primary p-3 mb-4">
                          <i
                            className={`${app.icon} app-switcher-icon text-white text-decoration-none`}
                          />
                        </Card>
                        <i className="fas fa-chevron-right text-primary" />
                      </div>
                      <h5 className="text-left">{app.name}</h5>
                    </Card.Body>
                  </Card>
                </Button>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </LayoutWithMenu>
  );
};

export default Dashboard;
