import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { RelationshipFields } from 'constants/fieldTypes';

// eslint-disable-next-line
import s from './FormLayout.module.scss';

const GridItems = ({ columns }) => (
  <div className="d-flex">
    {columns.map(column => (
      <span className="d-flex flex-column flex-fill" key={column.headerName}>
        <span className={s.gridHeader}>{column.headerName}</span>
        <span>{column.field}</span>
      </span>
    ))}
  </div>
);

GridItems.propTypes = {
  columns: PropTypes.array.isRequired,
};

const FormLayout = ({ fields, formLayout }) => (
  <div className={s.root}>
    {formLayout.fieldsets.map(fieldset => (
      <div key={fieldset.key} className={cx('container py-2', s.fieldset)}>
        <h3>{fieldset.label || fieldset.key}</h3>

        {fieldset.rows
          .map((row, idx) => ({ ...row, idx }))
          .map(row => (
            <Row className="mb-2" key={row.idx}>
              {row.columns.map(column => {
                const field = fields[column.fieldKey];

                return (
                  <Col
                    className={cx(
                      s.fieldKey,
                      s[field.ui_component],
                      {
                        [s.multiline]: field.ui_component_options?.multiLine,
                      },
                      {
                        [s.readonly]: field.ui_component_options?.readOnly,
                      },
                      {
                        [s.time]: field.ui_component_options?.time,
                      },
                      {
                        [s.precision]: field.ui_component_options?.precision,
                      },
                      {
                        [s.json]:
                          field.ui_component_options?.language === 'json',
                      },
                    )}
                    key={column.fieldKey}
                  >
                    {field.ui_component === 'Grid' &&
                    field.ui_component_options?.gridSettings?.agGrid
                      ?.columnDefs ? (
                      <GridItems
                        columns={
                          field.ui_component_options.gridSettings.agGrid
                            .columnDefs
                        }
                      />
                    ) : (
                      <div>
                        {(RelationshipFields.includes(field.ui_component) && (
                          <Link
                            to={`/settings/schemas/${field.relationshipOptions.related_entity}`}
                          >
                            {field.label}
                          </Link>
                        )) ||
                          field.label}

                        {field.validation_rules.required && (
                          <span className="text-danger"> *</span>
                        )}
                      </div>
                    )}
                  </Col>
                );
              })}
            </Row>
          ))}
      </div>
    ))}
  </div>
);

FormLayout.propTypes = {
  fields: PropTypes.object.isRequired,
  formLayout: PropTypes.object.isRequired,
};

export default FormLayout;
