import { useParams } from 'react-router-dom';

import { camelCase } from 'lodash';

import CustomUIComponent from 'components/CustomUIComponent/CustomUIComponent';

import LayoutWithMenu from '../components/Layout/LayoutWithMenu';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const CustomUIPage = () => {
  const { pageComponent } = useParams<{
    pageComponent: string;
  }>();

  // From testing_custom_page (page slug following the current s9 structure)
  // To TestingCustomPage (component name)
  const componentName = capitalizeFirstLetter(
    camelCase(pageComponent.toLowerCase()),
  );

  return (
    <LayoutWithMenu className="p-0">
      <CustomUIComponent componentName={componentName} />
    </LayoutWithMenu>
  );
};

export default CustomUIPage;
