import { useFormContext } from 'react-hook-form';

import cx from 'classnames';

type Props = {
  name: string;
  defaultValue?: number;
};
const NumericField = ({ name, defaultValue }: Props) => {
  const { register, errors } = useFormContext();
  const fieldName = name.replace('.value', '');

  return (
    <>
      <input
        className={cx(`form-control`, {
          'is-invalid': errors[fieldName],
        })}
        ref={register({
          pattern: {
            value: /^\d*\.?\d*$/,
            message: 'Invalid number',
          },
        })}
        type="string"
        name={name}
        id={name}
        autoComplete="off"
        defaultValue={defaultValue}
      />

      {errors[fieldName] && (
        <span className="text-danger">{errors[fieldName].value.message}</span>
      )}
    </>
  );
};

export default NumericField;
