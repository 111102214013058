import { createContext, useCallback, useReducer } from 'react';

import { get } from 'core/fetch';
import PropTypes from 'prop-types';

import reducer, { initialState, ActionTypes } from './state/SchemaState';

interface SchemaContextActions {
  fetchSchemas: () => Promise<void>;
  fetchEntityJsonSchema: (entityType: string) => Promise<void>;
}

interface SchemaContextState extends SchemaContextActions {
  state: any;
}

export const SchemaContext = createContext<SchemaContextState>({
  state: undefined,
  fetchSchemas: () => Promise.reject(),
  fetchEntityJsonSchema: () => Promise.reject(),
});

const SchemaContextProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  /*
   * fetchSchemas
   * Return list of entity types
   */
  const fetchSchemas = useCallback(async () => {
    dispatch({
      type: ActionTypes.FETCH_SCHEMAS_REQUEST,
      payload: [],
    });

    get(`/schema`)
      .then(response =>
        dispatch({
          type: ActionTypes.FETCH_SCHEMAS_SUCCESS,
          payload: response,
        }),
      )
      .catch(e =>
        dispatch({
          type: ActionTypes.FETCH_SCHEMAS_FAIL,
          payload: {
            e,
          },
        }),
      );
  }, [dispatch]);

  /*
   * fetchEntityJsonSchema
   * Return entity JSON schema
   * params: entity key
   */
  const fetchEntityJsonSchema = useCallback(
    async entityType => {
      dispatch({
        type: ActionTypes.FETCH_ENTITY_JSON_SCHEMA_REQUEST,
        payload: { entityType },
      });

      get(`/schema/${entityType}/info`)
        .then(response =>
          dispatch({
            type: ActionTypes.FETCH_ENTITY_JSON_SCHEMA_SUCCESS,
            payload: response,
          }),
        )
        .catch(e =>
          dispatch({
            type: ActionTypes.FETCH_ENTITY_JSON_SCHEMA_FAIL,
            payload: {
              entityType,
              e,
            },
          }),
        );
    },
    [dispatch],
  );

  const actions: SchemaContextActions = {
    fetchSchemas,
    fetchEntityJsonSchema,
  };

  return (
    <SchemaContext.Provider value={{ ...actions, state }}>
      {children}
    </SchemaContext.Provider>
  );
};

SchemaContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SchemaContextProvider;
