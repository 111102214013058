import { AxiosRequestConfig } from 'axios';
import api from 'core/api';

const getHeaders = () => ({
  'Content-Type': 'application/json',
});

const fetch = async (config: AxiosRequestConfig): Promise<any> => {
  const res = await api({
    ...config,
    headers: config.headers || getHeaders(),
  }).catch(err => {
    throw err.response.data;
  });

  return res.data;
};

/**
 * METHODS
 */
const get = async (url: string): Promise<any> => fetch({ url });

const post = async (
  url: string,
  data: any,
  headers?: any,
  onProgress?: (progressEvent: any) => void,
): Promise<any> =>
  fetch({ url, data, method: 'POST', headers, onUploadProgress: onProgress });

const download = async (url: string, data: any, headers?: any): Promise<any> =>
  fetch({ url, data, method: 'POST', headers, responseType: 'blob' });

const put = async (url: string, data: any): Promise<any> =>
  fetch({ url, data, method: 'PUT' });

const remove = async (url: string): Promise<any> =>
  fetch({ url, method: 'DELETE' });

export { get, post, put, remove, download };

export default fetch;
