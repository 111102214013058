export const outcomeLabels = {
  2: 'Rejected',
  1: 'Approved',
};

export const getOutcomeLabel = (workflowDefinition, workflowOutcome) => {
  if (workflowDefinition?.outcome?.failure && workflowOutcome === 2) {
    return workflowDefinition?.outcome?.failure.label;
  }
  if (workflowDefinition?.outcome?.success && workflowOutcome === 1) {
    return workflowDefinition?.outcome?.success.label;
  }

  return outcomeLabels[workflowOutcome];
};

export const getBadgeColor = (workflowOutcome, stepNumber) => {
  if (workflowOutcome) {
    return `badge-${workflowOutcome === 1 ? 'success' : 'danger'}`;
  }
  if (stepNumber === 1) {
    return 'badge-primary';
  }
  return 'badge-warning';
};
