import { useFormContext, Controller } from 'react-hook-form';

import { FilterFormValue } from '@april9/stack9-react';
import { decodeURIComponentSafely } from 'utils/objectTransformer';

import TagsInput from './TagsInput';

type Props = {
  name: string;
  defaultValue?: FilterFormValue;
};

const DefaultField = ({ name, defaultValue }: Props) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        id={`default-field-filter-${name}`}
        render={({ onChange, value: defaultValues }) => {
          // if has value, make sure it is returning only the string, instead of {value:'', label:''}
          const values = !defaultValues
            ? []
            : defaultValues.map(v => (v.label ? v.label : v));

          return (
            <TagsInput
              onChange={selectedTags => {
                const encodedSelectedTags = selectedTags.map(tag =>
                  encodeURIComponent(tag),
                );

                onChange(encodedSelectedTags);
              }}
              tags={values.map(value => decodeURIComponentSafely(value)) || []}
            />
          );
        }}
      />

      <small className="form-text text-muted text-break">
        Use TAB or ENTER to add multiple values. Paste multiline values from
        your CLIPBOARD.
      </small>
    </>
  );
};

export default DefaultField;
