import { Controller, useFormContext } from 'react-hook-form';

import { Editor } from '@tinymce/tinymce-react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useEntityContext } from 'contexts/EntityContext';

import FieldLabel from './FieldLabel';

const HTMLField = ({ name, entityKey, defaultValue, fieldItem }) => {
  const { control, errors } = useFormContext();
  const { insertFileUpload } = useEntityContext();
  const readOnly = fieldItem.ui_component_options?.readOnly || false;

  const images_upload_handler = async (
    blobInfo,
    success,
    failure,
    progress,
  ) => {
    try {
      const formData = new FormData();
      formData.append('files', blobInfo.blob());

      const response = await insertFileUpload(
        entityKey,
        name,
        formData,
        event => {
          progress(Math.round((95 * event.loaded) / event.total));
        },
      );

      if (!response?.url) {
        failure(`Image upload error`, { remove: true });
      }

      success(response.url);
    } catch (error) {
      failure(`Image upload error: ${error}`, { remove: true });
    }
  };

  return (
    <FieldLabel fieldItem={fieldItem}>
      <div data-cy={name} className={cx({ 'is-invalid': errors[name] })}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          className="Editor"
          render={({ onChange, onBlur, value: fieldValue }) => (
            <Editor
              value={fieldValue}
              disabled={readOnly}
              onEditorChange={onChange}
              onBlur={onBlur}
              init={{
                height: 250,
                forced_root_block: false,
                paste_data_images: true,
                images_upload_handler: images_upload_handler,
                content_css: '/htmlEditor.css',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image | code',
              }}
            />
          )}
        />

        {fieldItem.description && (
          <small className="form-text text-muted">
            {fieldItem.description}
          </small>
        )}
      </div>
    </FieldLabel>
  );
};

HTMLField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.shape({
      readOnly: PropTypes.bool,
    }),
  }).isRequired,
};

HTMLField.defaultProps = {
  defaultValue: '',
};

export default HTMLField;
