import { useContext, useRef, useEffect, useState } from 'react';
import { Alert, Badge, Overlay, Popover } from 'react-bootstrap';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { normaliseFormat } from 'utils/dateUtils';

import { AppContext } from 'contexts/AppContext';

import ActionLabels from '../../constants/ActionLabels';
import { outcomeLabels } from '../../utils/worflowUtils';

import s from './WorkflowStepper.module.scss';

const getOutcomeLabel = (group, approvedLabel, rejectedLabel) => {
  if (group.approvedStep) {
    return approvedLabel || outcomeLabels[1];
  }
  if (group.rejectedStep) {
    return rejectedLabel || outcomeLabels[2];
  }
  return group.stepName;
};

function WorkflowStepperDot({
  approvedLabel,
  rejectedLabel,
  group,
  settings,
  isLastItem,
}) {
  const [show, setShow] = useState<boolean>();
  const [target, setTarget] = useState<any>(null);
  const ref = useRef<any>(null);

  const isCurrentStep = isLastItem && !group.isOutcome;

  const getIcon = () => {
    if (!isLastItem) {
      return <i className="fas fa-check fa-sm text-success" />;
    }
    if (isCurrentStep) {
      return <i className="fas fa-ellipsis-h fa-sm text-warning" />;
    }
    if (group.approvedStep) {
      return <i className="fas fa-check fa-sm text-white" />;
    }
    if (group.rejectedStep) {
      return <i className="fas fa-times fa-sm text-white" />;
    }
    return <i className="fas fa-ellipsis-h fa-sm text-gray" />;
  };

  const handleMouseEnter = event => {
    setShow(true);
    setTarget(event.target);
  };

  const handleMouseLeave = event => {
    setShow(false);
    setTarget(event.target);
  };

  function getVariant() {
    if (group.approvedStep) {
      return 'success';
    }
    if (group.rejectedStep) {
      return 'danger';
    }
    return 'light-grey';
  }

  return (
    <div
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-cy="dot-container"
      className={cx(s.dotContainer, 'tooltip-ex', {
        [s.current]: isLastItem && !group.isOutcome,
        [s.rejected]: group.rejectedStep,
        [s.approved]: group.approvedStep,
        [s.done]: !isLastItem,
      })}
    >
      <div className={s.dot}>{getIcon()}</div>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref.current}
        containerPadding={20}
      >
        <Popover id="popover-basic" className={s.popover}>
          <Popover.Content>
            <div className="d-flex align-items-center">
              <Badge
                variant={getVariant()}
                className={cx('mr-2', {
                  'text-white': group.approvedStep || group.rejectedStep,
                })}
              >
                {getOutcomeLabel(group, approvedLabel, rejectedLabel)}
              </Badge>
            </div>
            {!!group.items?.length &&
              group.items.map(historyItem => (
                <div key={`${historyItem.id}${historyItem.date}`}>
                  <small>
                    {moment(historyItem.date)
                      .local()
                      .format(
                        normaliseFormat(
                          settings.dateFormat,
                          settings.timeFormat,
                        ),
                      )}
                    {': '}
                    <span className="font-weight-bold">
                      {ActionLabels[historyItem.workflow_action]}
                    </span>{' '}
                    by{' '}
                    <span className="font-weight-bold">
                      {historyItem.ownerName || historyItem.created_by || 'BOT'}{' '}
                    </span>
                    {historyItem.outcome_reason && (
                      <span>- {historyItem.outcome_reason}</span>
                    )}
                  </small>
                </div>
              ))}
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
}

WorkflowStepperDot.propTypes = {
  approvedLabel: PropTypes.string,
  rejectedLabel: PropTypes.string,
  group: PropTypes.any.isRequired,
  isLastItem: PropTypes.bool.isRequired,
  settings: PropTypes.any.isRequired,
};

WorkflowStepperDot.defaultProps = {
  approvedLabel: undefined,
  rejectedLabel: undefined,
};

const WorkflowStepper = ({ workflow, noAlert }) => {
  const {
    state: { settings },
  } = useContext(AppContext);

  const timelineContainer = useRef<any>();

  useEffect(() => {
    if (timelineContainer) {
      setTimeout(() => {
        if (timelineContainer.current)
          timelineContainer.current.scroll({
            left: timelineContainer.current.scrollWidth,
            behavior: 'smooth',
          });
      }, 400);
    }
  }, [timelineContainer]);

  return (
    <div>
      {workflow.outcomeReason && !noAlert && (
        <Alert
          data-cy="alert-workflow-reason"
          className={cx(s.squared, 'mb-3')}
          variant={cx({
            danger: workflow.outcome === 2,
            warning: !workflow.outcome,
          })}
        >
          {workflow.outcomeReason}
        </Alert>
      )}

      {!!workflow.historyGrouped?.length && (
        <div className={s.timelineContainer} ref={timelineContainer}>
          <div className={cx(s.dotContainer, s.created, 'tooltip-ex')}>
            <div className={s.dot} />
          </div>
          {workflow.historyGrouped.map((group, index) => {
            const isLastItem = index === workflow.historyGrouped.length - 1;
            return (
              <WorkflowStepperDot
                approvedLabel={workflow?.definition.outcome?.success?.label}
                rejectedLabel={workflow?.definition.outcome?.failure?.label}
                key={group.uniqueIndex}
                group={group}
                isLastItem={isLastItem}
                settings={settings}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

WorkflowStepper.propTypes = {
  noAlert: PropTypes.bool,
  workflow: PropTypes.any.isRequired,
};

WorkflowStepper.defaultProps = {
  noAlert: false,
};

export default WorkflowStepper;
