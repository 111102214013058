import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import PropTypes from 'prop-types';

const Checkbox = ({ name, defaultValue, entityKey, fieldItem }) => {
  const { register, errors } = useFormContext();
  const readOnly = fieldItem.ui_component_options?.readOnly || false;
  const id = `${entityKey}-checkbox-${name}`;

  return (
    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          name={name}
          type="checkbox"
          id={id}
          className={cx('custom-control-input', {
            'is-invalid': errors[name],
          })}
          ref={register}
          defaultChecked={defaultValue}
          disabled={readOnly}
        />
        <label className="custom-control-label" htmlFor={id}>
          {fieldItem.label}
        </label>

        {fieldItem.description && (
          <small className="form-text text-muted">
            {fieldItem.description}
          </small>
        )}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.shape({
    ui_component_options: PropTypes.shape({
      readOnly: PropTypes.bool,
    }),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

Checkbox.defaultProps = {
  defaultValue: false,
};

export default Checkbox;
