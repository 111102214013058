import ContentLoader from 'react-content-loader';

import PropTypes from 'prop-types';

type Props = {
  rows: number;
  radius?: number;
  width?: number;
};

const HistoryLoader = ({ rows, width, radius, ...props }: Props) => {
  const histories: Array<any> = [];
  const OFFSET = 88;
  for (let i = 0; i < rows; i += 1) {
    histories.push(
      <circle key={1 + i * 7} cx="20" cy={36 + i * OFFSET} r="20" />,
      <circle key={2 + i * 7} cx="52" cy={36 + i * OFFSET} r="20" />,
      <rect
        rx={radius}
        ry={radius}
        key={3 + i * 7}
        x="87"
        y={19 + i * OFFSET}
        width="221"
        height="17"
      />,
      <rect
        rx={radius}
        ry={radius}
        key={4 + i * 7}
        x="319"
        y={19 + i * OFFSET}
        width="263"
        height="17"
      />,
      <rect
        rx={radius}
        ry={radius}
        key={5 + i * 7}
        x="87"
        y={52 + i * OFFSET}
        width="221"
        height="17"
      />,
      <rect
        rx={radius}
        ry={radius}
        key={6 + i * 7}
        x="354.5"
        y={52 + i * OFFSET}
        width="217.5"
        height="16.7"
      />,
      <rect
        rx={radius}
        ry={radius}
        key={7 + i * 7}
        x="325"
        y={52 + i * OFFSET}
        width="16.7"
        height="16.7"
      />,
    );
  }
  const height = OFFSET * rows;
  return (
    <ContentLoader
      viewBox={`0 0 582 ${height}`}
      height={height}
      width={width}
      {...props}
    >
      {histories}
    </ContentLoader>
  );
};
HistoryLoader.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rows: PropTypes.number,
  radius: PropTypes.number,
};

HistoryLoader.defaultProps = {
  width: 582,
  rows: 4,
  radius: 3,
};

export default HistoryLoader;
