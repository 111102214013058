import { S9QueryCriteria, FieldItem } from '@april9/stack9-sdk';
import { parseWorkflowValues } from 'utils/filters';

export const normalizeValueToArray = (valueOrValues: any) =>
  Array.isArray(valueOrValues) ? valueOrValues : [valueOrValues];

export const FILTER_CRITERIA_CONTAINS = 'CONTAINS';
export const FILTER_CRITERIA_CONTAINS_LABEL = 'Contains';
export const FILTER_CRITERIA_CONTAINS_OBJECT = {
  label: FILTER_CRITERIA_CONTAINS_LABEL,
  value: FILTER_CRITERIA_CONTAINS,
  getFilterValue: (value): S9QueryCriteria => ({
    $like: `%${value}%`,
  }),
};
export const FILTER_CRITERIA_NOT_CONTAINS = 'NOT_CONTAINS';
export const FILTER_CRITERIA_NOT_CONTAINS_LABEL = 'Not Contains';
export const FILTER_CRITERIA_NOT_CONTAINS_OBJECT = {
  label: FILTER_CRITERIA_NOT_CONTAINS_LABEL,
  value: FILTER_CRITERIA_NOT_CONTAINS,
  getFilterValue: (value): S9QueryCriteria => ({
    $nlike: `%${value}%`,
  }),
};
export const FILTER_CRITERIA_EQUALS = 'EQUALS';
export const FILTER_CRITERIA_EQUALS_LABEL = 'Equals';
export const FILTER_CRITERIA_EQUALS_OBJECT = {
  label: FILTER_CRITERIA_EQUALS_LABEL,
  value: FILTER_CRITERIA_EQUALS,
  getFilterValue: (value): S9QueryCriteria => ({
    $eq: value,
  }),
};
export const FILTER_CRITERIA_NOT_EQUALS = 'NOT_EQUALS';
export const FILTER_CRITERIA_NOT_EQUALS_LABEL = 'Not Equals';
export const FILTER_CRITERIA_NOT_EQUALS_OBJECT = {
  label: FILTER_CRITERIA_NOT_EQUALS_LABEL,
  value: FILTER_CRITERIA_NOT_EQUALS,
  getFilterValue: (value): S9QueryCriteria => ({
    $ne: value,
  }),
};
export const FILTER_CRITERIA_GREATER_THAN = 'GREATER_THAN';
export const FILTER_CRITERIA_GREATER_THAN_LABEL = 'Greater Than';
export const FILTER_CRITERIA_GREATER_THAN_OBJECT = {
  label: FILTER_CRITERIA_GREATER_THAN_LABEL,
  value: FILTER_CRITERIA_GREATER_THAN,
  getFilterValue: (value): S9QueryCriteria => ({
    $gt: value,
  }),
};
export const FILTER_CRITERIA_LESS_THAN = 'LESS_THAN';
export const FILTER_CRITERIA_LESS_THAN_LABEL = 'Less Than';
export const FILTER_CRITERIA_LESS_THAN_OBJECT = {
  label: FILTER_CRITERIA_LESS_THAN_LABEL,
  value: FILTER_CRITERIA_LESS_THAN,
  getFilterValue: (value): S9QueryCriteria => ({
    $lt: value,
  }),
};
export const FILTER_CRITERIA_IN_RANGE = 'IN_RANGE';
export const FILTER_CRITERIA_IN_RANGE_LABEL = 'In Range';
export const FILTER_CRITERIA_IN_RANGE_OBJECT = {
  label: FILTER_CRITERIA_IN_RANGE_LABEL,
  value: FILTER_CRITERIA_IN_RANGE,
  getFilterValue: ([value1, value2]): S9QueryCriteria | null => {
    if (!value1 && !value2) return null;
    if (!value1) return { $lte: value2 };
    if (!value2) return { $gte: value1 };
    return {
      $gte: value1,
      $lte: value2,
    };
  },
};

export const FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN =
  'NOT_CONTAINS_MULTI_DROPDOWN';
export const FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN_LABEL =
  'Not Contains';
export const FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN_OBJECT = {
  label: FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN_LABEL,
  value: FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN,
  getFilterValue: (value): S9QueryCriteria => ({
    $nin: Array.isArray(value) ? value : [value],
  }),
};

export const FILTER_CRITERIA_WORKFLOW = 'WORKFLOW';
export const FILTER_CRITERIA_WORKFLOW_LABEL = 'WorkFlow';
export const FILTER_CRITERIA_WORKFLOW_OBJECT = {
  label: FILTER_CRITERIA_WORKFLOW_LABEL,
  value: FILTER_CRITERIA_WORKFLOW,
  getFilterValue: (valueOrValues: Array<number>): S9QueryCriteria | number => {
    const values = normalizeValueToArray(valueOrValues);
    const { workflowSteps, workflowOutcome } = parseWorkflowValues(values);

    const outcomeQuery = workflowOutcome.length
      ? {
          _workflow_outcome: {
            $in: workflowOutcome,
          },
        }
      : { _workflow_outcome: null };

    return {
      _workflow_current_step: {
        $in: workflowSteps,
      },
      ...outcomeQuery,
    };
  },
};

export const FILTER_CRITERIA_DEFAULT = 'DEFAULT';
export const FILTER_CRITERIA_DEFAULT_LABEL = 'Default';
export const FILTER_CRITERIA_DEFAULT_OBJECT = {
  label: FILTER_CRITERIA_DEFAULT_LABEL,
  value: FILTER_CRITERIA_DEFAULT,
  getFilterValue: (valueOrValues, fieldKey: string): S9QueryCriteria => {
    const values = normalizeValueToArray(valueOrValues);

    return values.length > 1
      ? {
          $or: values.map(value => ({
            [fieldKey]: { $like: `%${value}%` },
          })),
        }
      : { $like: `%${values[0]}%` };
  },
};

export const FILTER_CRITERIA_IN = 'IN';
export const FILTER_CRITERIA_IN_LABEL = 'In';
export const FILTER_CRITERIA_IN_OBJECT = {
  label: FILTER_CRITERIA_IN_LABEL,
  value: FILTER_CRITERIA_IN,
  getFilterValue: (value): S9QueryCriteria => ({
    $in: Array.isArray(value) ? value : [value],
  }),
};

export const FILTER_CRITERIAS_PER_TYPE = {
  [FILTER_CRITERIA_CONTAINS]: FILTER_CRITERIA_CONTAINS_OBJECT,
  [FILTER_CRITERIA_NOT_CONTAINS]: FILTER_CRITERIA_NOT_CONTAINS_OBJECT,
  [FILTER_CRITERIA_EQUALS]: FILTER_CRITERIA_EQUALS_OBJECT,
  [FILTER_CRITERIA_NOT_EQUALS]: FILTER_CRITERIA_NOT_EQUALS_OBJECT,
  [FILTER_CRITERIA_GREATER_THAN]: FILTER_CRITERIA_GREATER_THAN_OBJECT,
  [FILTER_CRITERIA_LESS_THAN]: FILTER_CRITERIA_LESS_THAN_OBJECT,
  [FILTER_CRITERIA_IN_RANGE]: FILTER_CRITERIA_IN_RANGE_OBJECT,
  [FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN]: FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN_OBJECT,
  [FILTER_CRITERIA_WORKFLOW]: FILTER_CRITERIA_WORKFLOW_OBJECT,
  [FILTER_CRITERIA_DEFAULT]: FILTER_CRITERIA_DEFAULT_OBJECT,
  [FILTER_CRITERIA_IN]: FILTER_CRITERIA_IN_OBJECT,
};

export const FILTER_CRITERIAS = {
  NumericField: [FILTER_CRITERIA_EQUALS_OBJECT],
  Checkbox: [FILTER_CRITERIA_IN_OBJECT],
  SingleDropDown: [FILTER_CRITERIA_IN_OBJECT],
  MultiDropDown: [FILTER_CRITERIA_IN_OBJECT],
  DateField: [FILTER_CRITERIA_IN_RANGE_OBJECT],
  WorkflowStep: [FILTER_CRITERIA_WORKFLOW_OBJECT],
  default: [FILTER_CRITERIA_DEFAULT_OBJECT],
};

export const workoutFilterCriteria = (filterFieldSchema: FieldItem) => {
  const filterCriteriaOperator =
    FILTER_CRITERIAS[filterFieldSchema.ui_component];

  const operator = filterCriteriaOperator
    ? filterCriteriaOperator[0]?.value
    : FILTER_CRITERIA_DEFAULT;

  return FILTER_CRITERIAS_PER_TYPE[operator];
};
