/* eslint-disable no-plusplus */
import { memo, useMemo } from 'react';
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  TimelineMarkers,
  TodayMarker,
} from 'react-calendar-timeline';

import { minBy, maxBy, orderBy } from 'lodash';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getPropValue } from 'utils/formUtils';

import TimelineItemRenderer from './TimelineItemRenderer';

import s from './TimelineView.module.scss';

const transformTimelineViewItems = (data, options, onItemClick) =>
  data.reduce(
    (prev, groupItem, index) => [
      ...prev,
      ...orderBy(
        groupItem.results.filter(
          item =>
            item[options.dateFields.start] && item[options.dateFields.end],
        ),
        ['id'],
        ['asc'],
      ).map(item => ({
        id: item.id,
        group: index,
        title: options.title.map(prop => getPropValue(prop, item)).join(' - '),
        start_time: moment(item[options.dateFields.start]),
        end_time: moment(item[options.dateFields.end]),
        canMove: false,
        canResize: false,
        onItemClick,
        workflowOutcome: item._workflow_outcome,
      })),
    ],
    [],
  );

const TimelineView = ({ data, options, onItemClick }) => {
  const { sourceField } = options.groupBy;

  const groups = data.map((group, index) => {
    const groupName = group[sourceField];
    return {
      id: index,
      title:
        !groupName || groupName === 'undefined' || groupName === 'null'
          ? 'Not specified'
          : groupName,
    };
  });

  const items = useMemo(
    () => transformTimelineViewItems(data, options, onItemClick),
    [data, options, onItemClick],
  );

  if (items.length === 0) return <></>;

  const firstRecord = minBy(items, (item: any) => item.start_time.toDate());
  const lastRecord = maxBy(items, (item: any) => item.end_time.toDate());

  return (
    <div className={s.root}>
      <Timeline
        groups={groups}
        items={items}
        defaultTimeStart={firstRecord.start_time.add(-12, 'hour')}
        defaultTimeEnd={lastRecord.end_time.add(12, 'hour')}
        itemRenderer={TimelineItemRenderer}
        stackItems
        onItemClick={onItemClick}
        timeSteps={{
          second: 1,
          minute: 1,
          hour: 1,
          day: 1,
          month: 1,
          year: 1,
        }}
      >
        <TimelineHeaders>
          <SidebarHeader unit="week">
            {({ getRootProps }) => <div {...getRootProps()}>Resources</div>}
          </SidebarHeader>
          <DateHeader unit="primaryHeader" />
          <DateHeader />
        </TimelineHeaders>
        <TimelineMarkers>
          <TodayMarker>
            {({ styles }) => (
              // date is value of current date. Use this to render special styles for the marker
              // or any other custom logic based on date:
              // e.g. styles = {...styles, backgroundColor: isDateInAfternoon(date) ? 'red' : 'limegreen'}
              <div
                style={{ ...styles, backgroundColor: 'var(--light-grey)' }}
              />
            )}
          </TodayMarker>
        </TimelineMarkers>
      </Timeline>
    </div>
  );
};

TimelineView.propTypes = {
  data: PropTypes.array.isRequired,
  options: PropTypes.shape({
    groupBy: PropTypes.shape({
      sourceType: PropTypes.string.isRequired,
      sourceField: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default memo(TimelineView);
