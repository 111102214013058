import ContentLoader from 'react-content-loader';

import PropTypes from 'prop-types';

const FormLoader = props => (
  <ContentLoader
    width="100%"
    height="1008px"
    viewBox="0 0 1140 1008"
    backgroundColor="#eaeced"
    foregroundColor="#ffffff"
    {...props}
  >
    <rect rx="3" ry="3" x="27" y="119" width="523.2" height="45" />
    <rect rx="3" ry="3" y="52" width="1140" height="2" />
    <rect rx="3" ry="3" x="27" y="21" width="36" height="11" />
    <rect rx="3" ry="3" x="27" y="91" width="72" height="11" />
    <rect rx="3" ry="3" x="27" y="446" width="17.2" height="16" />
    <rect rx="3" ry="3" x="52.8" y="446" width="263.2" height="16" />
    <rect rx="3" ry="3" x="52.8" y="469" width="263.2" height="16" />
    <rect rx="3" ry="3" x="27" y="238" width="1083" height="45" />
    <rect rx="3" ry="3" x="586.8" y="119" width="523.2" height="45" />
    <rect rx="3" ry="3" x="27" y="357" width="523.2" height="45" />
    <rect rx="3" ry="3" x="27" y="329" width="136.4" height="11" />
    <rect rx="3" ry="3" x="586.8" y="357" width="523.2" height="45" />
    <rect rx="3" ry="3" x="586.8" y="329" width="131.1" height="11" />
    <rect rx="3" ry="3" y="571" width="1140" height="2" />
    <rect rx="3" ry="3" y="519" width="1140" height="2" />
    <rect rx="3" ry="3" x="27" y="540" width="36" height="11" />
    <rect rx="3" ry="3" x="27" y="210" width="251" height="11" />
    <rect rx="3" ry="3" x="587" y="91" width="72" height="11" />
    <rect rx="3" ry="3" x="27" y="642" width="523.2" height="45" />
    <rect rx="3" ry="3" x="27" y="614" width="72" height="11" />
    <rect rx="3" ry="3" x="27" y="969" width="17.2" height="16" />
    <rect rx="3" ry="3" x="52.8" y="969" width="263.2" height="16" />
    <rect rx="3" ry="3" x="52.8" y="992" width="263.2" height="16" />
    <rect rx="3" ry="3" x="27" y="761" width="1083" height="45" />
    <rect rx="3" ry="3" x="586.8" y="642" width="523.2" height="45" />
    <rect rx="3" ry="3" x="27" y="880" width="523.2" height="45" />
    <rect rx="3" ry="3" x="27" y="852" width="136.4" height="11" />
    <rect rx="3" ry="3" x="586.8" y="880" width="523.2" height="45" />
    <rect rx="3" ry="3" x="586.8" y="852" width="131.1" height="11" />
    <rect rx="3" ry="3" x="27" y="733" width="251" height="11" />
    <rect rx="3" ry="3" x="587" y="614" width="72" height="11" />
    <circle cx="110" cy="94" r="3" />
    <circle cx="289" cy="213" r="3" />
    <circle cx="670" cy="94" r="3" />
    <circle cx="110" cy="617" r="3" />
    <circle cx="289" cy="736" r="3" />
    <circle cx="670" cy="617" r="3" />
  </ContentLoader>
);

FormLoader.propTypes = {
  speed: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
};

FormLoader.defaultProps = {
  speed: 2,
  width: '100%',
  backgroundColor: '#f3f3f3',
  foregroundColor: '#ecebeb',
};

export default FormLoader;
