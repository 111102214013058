import { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import MonacoEditor from 'react-monaco-editor';

import { get } from 'core/fetch';

type Props = {
  printableDocuments: Array<any>;
};

const PrintableDocs = ({ printableDocuments }: Props) => {
  const [templateId, setTemplateId] = useState(printableDocuments[0].id);
  const [template, setTemplate] = useState<any>(null);

  useEffect(() => {
    get(`/printable_document/${templateId}`).then(response => {
      setTemplate(response);
    });
  }, [templateId]);

  const PREVIEW_HEIGHT = 400;

  return (
    <>
      <h5>Available Templates</h5>
      <div className="row mb-2">
        <div className="col-12 col-md-6 mb-2">
          <select
            className="form-control px-2"
            onChange={e => setTemplateId(e.target.value)}
          >
            {printableDocuments.map(document => (
              <option key={document.id} value={document.id}>
                {document.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-6 mb-2">
          <a
            type="button"
            className="btn btn-block btn-outline-primary"
            href="/apps/-/printable_document"
            target="blank"
          >
            <i className="fa fa-plus mr-2" />
            Add template
          </a>
        </div>
      </div>

      <div>
        {template && (
          <Tab.Container id="printable-docs-tabs" defaultActiveKey="design">
            <Nav variant="tabs" className="mb-4">
              <Nav.Item>
                <Nav.Link eventKey="design">Design</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="source">Source</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="source" mountOnEnter>
                <div>
                  <MonacoEditor
                    options={{
                      lineHeight: 20,
                      renderLineHighlight: 'all',
                      minimap: { enabled: false },
                      autoIndent: 'full',
                      renderFinalNewline: true,
                      wordWrap: 'on',
                      wordWrapColumn: 80,
                      readOnly: true,
                    }}
                    height={PREVIEW_HEIGHT}
                    language="handlebars"
                    // todo tscheck (doesn't exist in the definition)
                    // initialValue={template.template}
                    value={template?.template}
                  />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="design" mountOnEnter>
                <iframe
                  src={`/api/${template.entity_key}/doc/${templateId}`}
                  title="description"
                  height={PREVIEW_HEIGHT}
                  width="100%"
                  frameBorder="0"
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        )}
      </div>
    </>
  );
};

export default PrintableDocs;
