import { IJsonEntityView, S9Query } from '@april9/stack9-sdk';

/**
 * Function responsible to merge parameters from S9Query and view definition
 * @param query S9Query
 * @param view Schema viewsDefinition view (selected view)
 */
const mergeViewAndQueryParams = (
  query: S9Query,
  view: IJsonEntityView,
): S9Query => {
  // Merge the condition of view.query.where with the applied filter if both exists
  const viewQueryWhere = view?.query?.where || {};
  const mergedWhere =
    viewQueryWhere && query.$where
      ? { $and: [query.$where, viewQueryWhere] }
      : { ...(query.$where || {}), ...viewQueryWhere };

  // If the view has his own gridColumns, if not get the default gridSettings
  const mergedSelect = [
    ...(view.viewOptions?.gridSettings?.agGrid?.columnDefs || []).map(
      item => item.field,
    ),
    ...(view?.query?.select || []),
    ...(query.$select || []),
  ];

  const viewQueryWithRelated = view?.query?.withRelated || [];
  const mergedWithRelated = [
    ...(query.$withRelated || []),
    ...viewQueryWithRelated,
  ];

  const $sort = query.$sort || view.viewOptions?.defaultSort;

  return {
    $where: mergedWhere,
    $select: mergedSelect,
    $withRelated: mergedWithRelated,
    $groupBy: query.$groupBy,
    $sort,
  };
};

const prepareSortFromObject = $sort => {
  if (!$sort) {
    return [];
  }

  return Object.entries($sort)[0] as string[];
};

export { mergeViewAndQueryParams, prepareSortFromObject };
