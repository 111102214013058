import Modal from 'react-bootstrap/Modal';

import cx from 'classnames';
import PropTypes from 'prop-types';

import EntityMain from 'components/Entity/EntityMain/EntityMain';
import WorkflowInfo from 'components/WorkflowInfo/WorkflowInfo';
import { useDialogManager } from 'contexts/DialogManagerContext';
import { useEntityContext } from 'contexts/EntityContext';

import ButtonLoader from '../../Loaders/ButtonLoader';

import s from './EntityFormModal.module.scss';

const EntityFormModal = ({
  show,
  handleClose,
  entityId,
  entityKey,
  modalLevel,
}) => {
  const { getEntityState } = useEntityContext();

  const { getDialog } = useDialogManager();

  const entityState = getEntityState(entityKey, entityId);

  const onHide = () => {
    const dialog = getDialog();

    if (
      dialog.params.beforeHideCallback &&
      !dialog.params.beforeHideCallback()
    ) {
      return false;
    }

    handleClose();

    return true;
  };

  return (
    <Modal
      enforceFocus={false}
      show={show}
      onHide={onHide}
      size="xl"
      backdrop="static"
      backdropClassName={`modal-level-${modalLevel}`}
      className={cx(`modal-level-${modalLevel}`, s.modal)}
    >
      <Modal.Header className={s.header}>
        <div className="d-flex align-items-center justify-content-between w-100">
          {!entityState?.isLoading ? (
            <>
              <Modal.Title>
                <h4>
                  {entityId
                    ? `Editing ${entityState?.schema?.name} #${entityId}`
                    : `Create ${entityState?.schema?.name}`}
                </h4>
              </Modal.Title>
              <div className="d-flex align-items-center h4">
                {entityState?.schema?.workflow?.currentStep && (
                  <WorkflowInfo workflow={entityState?.schema.workflow} />
                )}
              </div>
            </>
          ) : (
            <>
              <ButtonLoader width={300} />
              <div className="d-flex">
                <ButtonLoader width={30} className="mr-2" />
                <ButtonLoader width={50} />
              </div>
            </>
          )}
        </div>
        <button
          type="button"
          className="close"
          data-cy="btn-close-window"
          onClick={onHide}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>

      <Modal.Body>
        <EntityMain entityKey={entityKey} entityId={entityId} />
      </Modal.Body>
    </Modal>
  );
};

EntityFormModal.propTypes = {
  show: PropTypes.bool,
  entityId: PropTypes.number,
  entityKey: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  modalLevel: PropTypes.number.isRequired,
};

EntityFormModal.defaultProps = {
  show: false,
  entityId: undefined,
  handleClose: () => {},
};

export default EntityFormModal;
