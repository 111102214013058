import { memo, useEffect } from 'react';

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as Lesri from 'esri-leaflet';
import PropTypes from 'prop-types';

const { L } = window;

const MarkerIcon = L.Icon.extend({
  options: {
    iconUrl: '/leaflet/marker-icon.png',
    shadowUrl: '/leaflet/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  },
});

const placeMarker = (lFeature, markerLocation, payload) => {
  const popup = L.popup({
    minWidth: 300,
  }).setContent(
    `<div id="popup-wrapper-${payload.entity.id}" style="min-height: 200px;"><div class="fa-2x mt-4 mb-4">
    <i class="fas fa-spinner fa-spin"></i></div></div>`,
  );

  // todo: tscheck, doesn't exist in the definition.
  (popup as any).payload = payload;

  L.marker(markerLocation, { icon: new MarkerIcon() })
    .addTo(lFeature)
    .bindPopup(popup);
};

const placeMarkers = (lFeature, data, options) => {
  const { locationField } = options;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; data.length > i; i++) {
    if (!data[i][locationField]) continue;

    const { x, y } = data[i][locationField];

    if (data[i]) placeMarker(lFeature, [x, y], { entity: data[i], options });
  }
};

/**
 *
 * @param {map} Map map instance
 * @param {data} Object listData, results from database
 * @param {locationField} String name of the field from the entity containing lat and long.
 */
const MapMarkers = ({ map, data, options }) => {
  // eslint-disable-next-line no-multi-assign
  const lFeature = L.featureGroup().addTo(map);

  useEffect(() => {
    lFeature.clearLayers();
    placeMarkers(lFeature, data, options);

    const bounds = lFeature.getBounds();
    if (bounds.isValid()) {
      map.fitBounds(bounds);

      if (map.getZoom() > 15) {
        map.setZoom(15);
      }
    }

    return () => {
      lFeature.clearLayers();
    };
  }, [data, lFeature, options, map]);

  return <></>;
};

MapMarkers.propTypes = {
  map: PropTypes.any.isRequired,
  data: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
};

export default memo(MapMarkers);
