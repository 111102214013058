import { useContext, useEffect } from 'react';

import LayoutWithMenu from 'components/Layout/LayoutWithMenu';
import { SchemaList as List } from 'components/Schema';
import { SchemaContext } from 'contexts/SchemaContext';

const SchemaList = () => {
  const { fetchSchemas, state } = useContext(SchemaContext);

  useEffect(() => {
    fetchSchemas();
  }, [fetchSchemas]);

  return (
    <LayoutWithMenu>
      <List entityTypes={state.entityTypes} />
    </LayoutWithMenu>
  );
};

export default SchemaList;
