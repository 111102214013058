// eslint-disable-next-line no-unused-vars
import moment from 'moment';

type Props = {
  format?: string;
  fromNow?: boolean;
  children: string;
};
const Moment = ({ format, fromNow, children }: Props) => {
  if (fromNow) {
    return <>{moment(children).fromNow()}</>;
  }

  return <>{moment(children).format(format)}</>;
};

Moment.defaultProps = {
  format: 'DD/MM/YYYY hh:mm A',
  fromNow: false,
};

export default Moment;
