import jwtDecode from 'jwt-decode';
import { getCookie, deleteCookie } from 'utils/cookie';

const apiToken = getCookie('api-token');

export enum AuthActions {
  Logout = '[Auth] Logout',
  LogoutSuccess = '[Auth] Logout Success',
  LogoutFail = '[Auth] Logout Fail',
}

interface IAction {
  type: AuthActions;
  payload?: any;
}

const initialState = {
  isLoading: false,
  error: null,
  authenticatedUser: apiToken
    ? jwtDecode<{ data: any }>(apiToken)?.data
    : false,
};

const reducer = (state: any, action: IAction): any => {
  switch (action.type) {
    case AuthActions.Logout:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActions.LogoutSuccess:
      deleteCookie('api-token');

      return {
        ...state,
        ...initialState,
      };
    case AuthActions.LogoutFail:
      // localStorage.clear();
      return {
        ...state,
        ...initialState,
      };
    default:
      throw new Error();
  }
};

export { initialState };

export default reducer;
