import React, {
  createContext,
  useReducer,
  useCallback,
  useEffect,
} from 'react';

import { get } from 'core/fetch';
import PropTypes from 'prop-types';

import changeThemeColor from '../utils/changeThemeColor';
import reducer, { initialState, ActionTypes } from './state/AppState';

interface AppContextActions {
  fetchApps: () => Promise<void>;
  fetchMe: () => Promise<void>;
  fetchQuickSightEmbedUrl: (appKey: string, editMode: boolean) => void;
  changeMenuItems: (appKey: string | null) => void;
}

interface AppState {
  state: any;
  actions: AppContextActions;
}

export const AppContext = createContext<AppState>({
  state: { settings: undefined },
  actions: {
    fetchApps: () => Promise.reject(),
    fetchMe: () => Promise.reject(),
    fetchQuickSightEmbedUrl: () => Promise.reject(),
    changeMenuItems: () => Promise.reject(),
  },
});

const AppContextProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  /*
   * ACTIONS
   */
  /*
   * fetchApps
   * Return available apps
   */
  const fetchApps = useCallback(async () => {
    dispatch({ type: ActionTypes.FETCH_APPS_REQUEST });

    get(`/app`)
      .then(res =>
        dispatch({ type: ActionTypes.FETCH_APPS_SUCCESS, payload: res }),
      )
      .catch(e => dispatch({ type: ActionTypes.FETCH_APPS_FAIL, payload: e }));
  }, []);

  /*
   * fetchMe
   * Return user logged in
   */
  const fetchMe = useCallback(async () => {
    dispatch({ type: ActionTypes.FETCH_ME_REQUEST });

    get(`/me`)
      .then(res =>
        dispatch({ type: ActionTypes.FETCH_ME_SUCCESS, payload: res }),
      )
      .catch(e => dispatch({ type: ActionTypes.FETCH_ME_FAIL, payload: e }));
  }, []);

  /*
   * fetchQuickSight dashboard
   * Return embedUrl
   */
  const fetchQuickSightEmbedUrl = useCallback(
    async (appKey: string, editMode: boolean) => {
      dispatch({ type: ActionTypes.FETCH_QUICKSIGHT_DASHBOARD_REQUEST });

      return get(`/quicksightdash/${appKey}${editMode ? '/edit' : ''}`)
        .then(res =>
          dispatch({
            type: ActionTypes.FETCH_QUICKSIGHT_DASHBOARD_SUCCESS,
            payload: res,
          }),
        )
        .catch(e =>
          dispatch({
            type: ActionTypes.FETCH_QUICKSIGHT_DASHBOARD_FAIL,
            payload: e,
          }),
        );
    },
    [],
  );

  useEffect(() => {
    changeThemeColor(state.settings.themeColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.settings.themeColor]);

  /*
   * changeMenuItems
   * Change Menu items by app key
   */
  const changeMenuItems = async (appKey: string | null) => {
    const menuItems = state.apps.find(app => app.key === appKey)?.menu;

    dispatch({
      type: ActionTypes.MENU_ITEMS_CHANGED,
      payload: {
        menuItems,
        selectedApp: appKey,
      },
    });
  };

  const actions: AppContextActions = {
    fetchApps,
    fetchMe,
    fetchQuickSightEmbedUrl,
    changeMenuItems,
  };

  return (
    <AppContext.Provider
      value={{
        actions,
        state,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AppContextProvider;
