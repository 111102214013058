import { useEffect, useRef, useCallback, ReactNode } from 'react';

import cx from 'classnames';

import s from './Popover.module.scss';

type Props = {
  id;
  children: ReactNode;
  className?: string;
  isActive?: boolean;
  setIsActive: (active) => void;
  showDrop?: boolean;
  toggle: ReactNode;
  width?: number;
};

const Popover = ({
  id,
  children,
  className,
  isActive = false,
  setIsActive,
  showDrop = false,
  toggle,
  width = 230,
}: Props) => {
  const node = useRef<any>();
  const closePopover = useCallback(
    e => {
      if (node.current.contains(e.target)) return; // inside click

      setIsActive(false);
    },
    [setIsActive],
  );

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', closePopover);
    } else {
      document.removeEventListener('mousedown', closePopover);
    }

    return () => {
      document.removeEventListener('mousedown', closePopover);
    };
  }, [closePopover, isActive]);

  return (
    <div className={s.popover} ref={node}>
      <a
        aria-haspopup="true"
        aria-expanded={isActive}
        id={id}
        role="button"
        href="#!"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setIsActive(!isActive);
        }}
        className={cx(s.toggleButton, className, {
          [s.isActive]: isActive,
          [s.showDrop]: showDrop,
        })}
      >
        {toggle}
      </a>

      <div
        style={{
          width,
        }}
        onClick={e => e.stopPropagation()}
        role="presentation"
        className={cx(s.content, {
          [s.isActive]: isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Popover;
