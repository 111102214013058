/* eslint-disable import/no-unresolved */
import { useFormContext } from 'react-hook-form';

import { createRequires } from '@paciolan/remote-component/dist/createRequires';
import { createUseRemoteComponent } from '@paciolan/remote-component/dist/hooks/useRemoteComponent';
import fetch from 'core/fetch';
import PropTypes from 'prop-types';

import { useDialogManager } from 'contexts/DialogManagerContext';

const requires = createRequires({
  // eslint-disable-next-line global-require
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  react: require('react'),
});
const useRemoteComponent = createUseRemoteComponent({ requires });

const CustomUIComponent = ({
  name,
  defaultValue,
  entityId,
  entityKey,
  fieldItem,
}) => {
  const formContext = useFormContext();
  const dialogManagerContext = useDialogManager();

  // TODO: Add the static URL here : )
  const url = `/custom-ui-component/${fieldItem.ui_component_options.component}.js`;

  const placeholder = fieldItem?.placeholder;

  const componentProps = {
    name,
    defaultValue,
    placeholder,
    entityId,
    entityKey,
    fieldItem,
    formContext,
    dialogManagerContext,
    fetch,
  };

  const [loading, err, Component] = useRemoteComponent(url);

  if (loading) {
    return <div>Loading {name}...</div>;
  }

  if (err != null) {
    return (
      <div>
        Error to render custom field {name}: {err.toString()}
      </div>
    );
  }

  return <Component {...componentProps} />;
};

CustomUIComponent.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  entityId: PropTypes.number, // eslint-disable-line
  entityKey: PropTypes.string.isRequired,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.shape({
      component: PropTypes.string,
    }),
  }).isRequired,
};

CustomUIComponent.defaultProps = {
  defaultValue: '',
};

export default CustomUIComponent;
