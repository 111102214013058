import { Fragment, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import { FormMode } from 'core/formMode';
import PropTypes from 'prop-types';

import { IronBody } from 'components/Layout';

import Checkbox from './Checkbox';
import ColorPickerField from './ColorPickerField';
import CustomUIComponent from './CustomUIComponent';
import DatePickerField from './DatePickerField';
import FileField from './FileField/FileField';
import Grid from './Grid';
import HTMLField from './HTMLField';
import './Fieldsets.scss';
import MapLocation from './MapLocation';
import MonacoEditorField from './MonacoEditorField';
import MultiDropDown from './MultiDropDown';
import NumericField from './NumericField';
import OptionSet from './OptionSet';
import PrivilegiesMatrix from './PrivilegiesMatrix/PrivilegiesMatrix';
import SingleDropDown from './SingleDropDown';
import TextField from './TextField';

const getField = (fieldType, props) => {
  const fieldTypes = {
    TextField: <TextField {...props} />,
    NumericField: <NumericField {...props} />,
    SingleDropDown: <SingleDropDown {...props} />,
    MultiDropDown: <MultiDropDown {...props} />,
    MapLocation: <MapLocation {...props} />,
    Grid: <Grid {...props} />,
    Checkbox: <Checkbox {...props} />,
    CustomUIComponent: <CustomUIComponent {...props} />,
    DateField: <DatePickerField {...props} />,
    FileField: <FileField {...props} />,
    HTMLField: <HTMLField {...props} />,
    PrivilegiesMatrix: <PrivilegiesMatrix {...props} />,
    MonacoEditorField: <MonacoEditorField {...props} />,
    OptionSet: <OptionSet {...props} />,
    ColorPickerField: <ColorPickerField {...props} />,
  };

  return (
    fieldTypes[fieldType] || (
      <div>
        <small>Field "{fieldType}" hasn't been implemented.</small>
      </div>
    )
  );
};

const getFieldValue = (data, fieldItem) => {
  if (data) {
    switch (fieldItem?.ui_component) {
      case 'Grid':
      case 'SingleDropDown':
      case 'MultiDropDown':
        return data[fieldItem.relationshipOptions.name];
      default:
        return data[fieldItem.key];
    }
  }

  return undefined;
};

const FieldGroup = ({ fields, layout, data, entityKey, entityId, mode }) => {
  const { errors } = useFormContext();

  const [visible, setVisible] = useState(!layout.isCollapsed);

  return (
    <Card className="fieldset mb-4 border-0 shadow-sm" id={`${layout.key}`}>
      <Card.Body>
        <div className="py-0 px-0">
          <div
            role="button"
            tabIndex={0}
            onKeyDown={e => {
              if ([13, 32].indexOf(e.keyCode) > -1) {
                setVisible(!visible);
                e.preventDefault();
              }
            }}
            onClick={() => setVisible(!visible)}
            className={cx('section-toggle', {
              'is-visible': visible,
            })}
          >
            <div>{layout.label || layout.key}</div>
            <div className="toggle">
              <i
                className={cx('far', {
                  'far fa-chevron-down': visible,
                  'far fa-chevron-up': !visible,
                })}
              />
            </div>
          </div>
        </div>
        <div
          className={cx('fieldset-body', {
            'd-none': !visible,
          })}
        >
          <>
            {layout?.rows.map((rows, rowIdx) => (
              <div
                id={`${layout.key}`}
                // eslint-disable-next-line
                key={`${layout.key}-row-${rowIdx}`}
                aria-labelledby={`${layout.key}-row-${rowIdx}`}
                data-parent="#fieldsets"
              >
                <div className="row">
                  {rows?.columns.map(column => {
                    const item = fields[column.fieldKey];
                    const fieldItem = {
                      ...item,
                      ui_component_options: {
                        ...item?.ui_component_options,
                        readOnly:
                          mode === FormMode.readonly ||
                          item?.ui_component_options?.readOnly,
                      },
                    };

                    if (column.fieldKey === 'id') {
                      return (
                        <div className="col-md col-12" key={column.fieldKey}>
                          <div className="form-group">
                            <label>Identifier</label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={entityId}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (!fieldItem) {
                      return (
                        <div className="col-md col-12" key={column.fieldKey}>
                          <div className="form-group text-danger">
                            Field "{column.fieldKey}" not found
                          </div>
                        </div>
                      );
                    }

                    return (
                      <Fragment key={fieldItem.key}>
                        <div className="col-md col-12">
                          {getField(fieldItem.ui_component, {
                            name: fieldItem.key,
                            defaultValue: getFieldValue(data, fieldItem),
                            fieldItem,
                            description: fieldItem.description,
                            entityKey,
                            entityId,
                            data,
                          })}
                          <span className="text-danger">
                            {errors[column.fieldKey]?.message}
                          </span>
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            ))}
          </>
        </div>
      </Card.Body>
    </Card>
  );
};

const Fieldsets = props => (
  <IronBody>
    {props.fieldsets.map(fieldset => (
      <FieldGroup layout={fieldset} {...props} key={fieldset.key} />
    ))}
  </IronBody>
);

FieldGroup.propTypes = {
  fields: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
  data: PropTypes.object,
  entityId: PropTypes.number,
  mode: PropTypes.string.isRequired,
};

FieldGroup.defaultProps = {
  data: undefined,
  entityId: undefined,
};

Fieldsets.propTypes = {
  fields: PropTypes.object.isRequired,
  fieldsets: PropTypes.array.isRequired,
  data: PropTypes.object,
  entityKey: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  mode: PropTypes.string.isRequired,
};

Fieldsets.defaultProps = {
  data: undefined,
  entityId: undefined,
};

export default Fieldsets;
