import { useEffect, useRef } from 'react';

// https://www.jayfreestone.com/writing/react-portals-with-hooks/
export default function usePortal(element) {
  const rootElemRef = useRef(document.createElement('div'));

  useEffect(() => {
    if (element) {
      // Add the detached element to the parent
      const { current: rootElemRefCurrent } = rootElemRef;
      element.appendChild(rootElemRefCurrent);
      // This function is run on unmount
      return function removeElement() {
        rootElemRefCurrent.remove();
      };
    }
    return undefined;
  }, [element]);

  return rootElemRef.current;
}
