import { Button } from 'react-bootstrap';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

type Props = {
  title?: string;
  onHide?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  modalLevel?: number;
  cancelMessage?: string;
  confirmMessage?: string;
} & ModalProps;

const ConfirmationExitModal = ({
  title,
  onHide,
  onCancel,
  onConfirm,
  modalLevel,
  cancelMessage,
  confirmMessage,
  ...props
}: Props) => (
  <Modal
    backdropClassName={`modal-level-${modalLevel}`}
    className={`modal-level-${modalLevel}`}
    onHide={onHide}
    {...props}
  >
    <Modal.Header className="border-0" closeButton>
      <Modal.Title>
        <h5 className="text-center text-dark pt-3">{title}</h5>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="d-flex justify-content-center px-4 pb-4">
      <Button variant="primary" className="mr-2" onClick={onCancel}>
        {cancelMessage}
      </Button>
      <Button
        variant="outline-primary"
        data-cy="btn-confirm-exit"
        onClick={onConfirm}
      >
        {confirmMessage}
      </Button>
    </Modal.Body>
  </Modal>
);

ConfirmationExitModal.defaultProps = {
  show: false,
  onHide: () => {},
  onCancel: () => {},
  onConfirm: () => {},
  modalLevel: 1,
  cancelMessage: 'Cancel',
  confirmMessage: 'Close without saving',
  title: 'Are you sure you want to close without saving?',
};

export default ConfirmationExitModal;
