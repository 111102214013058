const Stack9Icon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 137.6 49.2" {...props}>
    <g id="Layer_2_1_">
      <g id="Layer_1-2">
        <path
          style={{ fill: '#231F20' }}
          d="M60.8,28.8c0.1,1.8-1.3,3.3-3.1,3.4c-0.1,0-0.2,0-0.3,0h-5.6c-0.3,0-0.6-0.2-0.6-0.6
			s0.2-0.7,0.5-0.7h5.8c1,0.1,1.8-0.6,1.9-1.6c0-0.1,0-0.2,0-0.4v-1.8c0-1-0.7-1.8-1.7-1.9l-3.9-0.4c-1.8-0.1-3.2-1.5-3.2-3.3v-1.6
			c0-1.8,1.4-3.3,3.2-3.3c0,0,0.1,0,0.1,0h5.7c0.4,0,0.5,0.2,0.5,0.7c0,0.3-0.2,0.5-0.4,0.6c-0.1,0-0.1,0-0.2,0h-5.8
			c-1,0-1.9,0.9-1.9,1.9c0,0,0,0.1,0,0.1v1.7c0,0.9,0.7,1.7,1.7,1.8l3.9,0.4c1.8,0.1,3.2,1.5,3.3,3.3L60.8,28.8z"
        />
        <path
          style={{ fill: '#231F20' }}
          d="M71.2,31.7c0,0.3-0.3,0.5-0.7,0.5s-0.8-0.2-0.8-0.5V18h-4.3c-0.3,0-0.5-0.2-0.5-0.6
			s0.2-0.7,0.5-0.7h10.2c0.3,0,0.5,0.2,0.5,0.7S75.9,18,75.5,18h-4.4V31.7z"
        />
        <path
          style={{ fill: '#231F20' }}
          d="M90.4,31.7c0,0.4-0.2,0.5-0.7,0.5s-0.7-0.2-0.7-0.5v-4.9h-7.9v4.9c0,0.4-0.2,0.5-0.7,0.5
			s-0.7-0.2-0.7-0.5v-8.9c-0.1-1.1,0.3-2.1,1.1-2.8l3.1-3.1c0.3-0.3,0.7-0.5,1.1-0.4c0.4,0,0.8,0.2,1.1,0.4l3.1,3.1
			c0.8,0.7,1.1,1.8,1.1,2.8L90.4,31.7z M89.1,25.6v-2.8c0.1-0.8-0.2-1.6-0.8-2.1l-3.2-3.2L82,20.7c-0.6,0.6-0.9,1.3-0.8,2.1v2.8
			L89.1,25.6z"
        />
        <path
          style={{ fill: '#231F20' }}
          d="M105.2,32.2h-6c-1.9,0-3.5-1.5-3.5-3.5c0-0.1,0-0.1,0-0.2v-8.4c-0.1-1.9,1.4-3.5,3.3-3.6
			c0.1,0,0.1,0,0.2,0h6.1c0.2,0.1,0.4,0.3,0.4,0.7s-0.2,0.7-0.5,0.7h-6.2c-1.1,0-2,0.9-2,2c0,0.1,0,0.1,0,0.2v8.6
			c-0.1,1.1,0.8,2.1,1.9,2.1c0,0,0.1,0,0.1,0h6.1c0.3,0,0.5,0.2,0.5,0.7S105.5,32.2,105.2,32.2L105.2,32.2z"
        />
        <path
          style={{ fill: '#231F20' }}
          d="M121.3,31.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0.3-0.3,0.6-0.6,0.6c0,0,0,0,0,0c-0.3,0-0.6-0.2-0.8-0.5
			l-4.7-7.5l-2.6,3.1v4.4c0,0.4-0.2,0.5-0.7,0.5s-0.7-0.2-0.7-0.5V17.1c0-0.4,0.2-0.5,0.7-0.5s0.7,0.2,0.7,0.5v8.1l7.2-8.4
			c0.2-0.2,0.4-0.3,0.6-0.3c0.3,0,0.6,0.3,0.6,0.6c0,0.2,0,0.3-0.3,0.6l-4.7,5.4L121.3,31.1z"
        />
        <path
          style={{ fill: '#231F20' }}
          d="M137.6,28.1c0,2.2-1.7,4-3.9,4.1c-0.1,0-0.1,0-0.2,0h-2.9c-2.1,0-3.8-1.1-3.8-3.2v-0.2
			c0-0.6,0.5-1.1,1.1-1.1c0,0,0.1,0,0.1,0c0.7,0,1.3,0.3,1.3,1.1v0.3c0,0.4,0.3,0.8,1,0.8h3.1c0.9,0.1,1.7-0.6,1.7-1.6
			c0-0.1,0-0.1,0-0.2v-2.5c-0.2,0.1-0.5,0.3-0.7,0.5c-0.5,0.5-1.2,0.7-1.8,0.7h-2.4c-2,0-3.6-1.6-3.7-3.5c0,0,0-0.1,0-0.1v-2.2
			c-0.1-2,1.5-3.7,3.5-3.7c0.1,0,0.1,0,0.2,0h3.3c2.1,0,3.9,1.6,3.9,3.8c0,0,0,0.1,0,0.1L137.6,28.1z M132.8,24.5
			c0.5,0,1-0.2,1.3-0.5c0.2-0.2,0.4-0.4,0.6-0.6s0.2-0.2,0.4-0.3V21c0-0.7-0.5-1.4-1.2-1.4c0,0-0.1,0-0.1,0h-3.5
			c-0.7,0-1.3,0.5-1.3,1.2c0,0,0,0,0,0v2.6c0,0.6,0.5,1.1,1.1,1.1H132.8z"
        />
        <path
          style={{ fill: '#231F20' }}
          d="M43.6,34.9V14.3c0-1.4-0.8-2.7-2-3.4L23.8,0.5c-1.2-0.7-2.7-0.7-3.9,0L2,10.9c-1.2,0.7-2,2-2,3.4
			v20.7c0,1.4,0.8,2.7,2,3.4l17.9,10.3c1.2,0.7,2.7,0.7,3.9,0l17.9-10.3C42.9,37.6,43.6,36.3,43.6,34.9z"
        />
        <g style={{ opacity: 0.2 }}>
          <path
            style={{ fill: '#FFFFFF' }}
            d="M7.9,31l13.4-7.8c0.4-0.2,1-0.2,1.4,0L36.3,31c0.7,0.4,0.9,1.3,0.5,2c-0.1,0.2-0.3,0.4-0.5,0.5
				l-13.4,7.8c-0.4,0.3-1,0.3-1.4,0L8,33.5c-0.7-0.4-1-1.2-0.6-1.9C7.5,31.3,7.7,31.1,7.9,31z"
          />
          <g>
            <path
              style={{ fill: '#FFFFFF' }}
              d="M7.9,31l13.4-7.8c0.4-0.2,1-0.2,1.4,0L36.3,31c0.7,0.4,0.9,1.3,0.5,2c-0.1,0.2-0.3,0.4-0.5,0.5
					l-13.4,7.8c-0.4,0.3-1,0.3-1.4,0L8,33.5c-0.7-0.4-1-1.2-0.6-1.9C7.5,31.3,7.7,31.1,7.9,31z"
            />
            <g>
              <defs>
                <path
                  id="SVGID_1_"
                  d="M7.9,31l13.4-7.8c0.4-0.2,1-0.2,1.4,0L36.3,31c0.7,0.4,0.9,1.3,0.5,2c-0.1,0.2-0.3,0.4-0.5,0.5
							l-13.4,7.8c-0.4,0.3-1,0.3-1.4,0L8,33.5c-0.7-0.4-1-1.2-0.6-1.9C7.5,31.3,7.7,31.1,7.9,31z"
                />
              </defs>
              <clipPath id="SVGID_2_">
                <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
              </clipPath>
              <g style={{ clipPath: 'url(#SVGID_2_)' }}>
                <polyline
                  style={{ fill: '#FFFFFF' }}
                  points="22.2,41.7 5.8,32.3 22.1,22.8 38.4,32.3 22.2,41.7 						"
                />
              </g>
            </g>
          </g>
        </g>
        <path
          style={{ opacity: 0.4, fill: '#FFFFFF' }}
          d="M8.1,24.2l13.3-7.7c0.5-0.3,1-0.3,1.5,0l13.4,7.7
			c0.7,0.4,1,1.3,0.6,2c-0.1,0.2-0.3,0.4-0.6,0.6l-13.2,7.7c-0.5,0.3-1,0.3-1.5,0L8.1,26.8c-0.7-0.4-1-1.3-0.6-2.1
			C7.6,24.5,7.8,24.3,8.1,24.2z"
        />
        <g style={{ opacity: 0.7 }}>
          <path
            style={{ fill: '#FFFFFF' }}
            d="M7.2,16.9l13.8-8c0.4-0.2,0.8-0.2,1.2,0l13.9,8c0.6,0.3,0.8,1.1,0.4,1.7c-0.1,0.2-0.3,0.3-0.4,0.4
				l-13.8,8c-0.4,0.2-0.8,0.2-1.2,0L7.2,19c-0.6-0.3-0.8-1.1-0.5-1.7C6.9,17.1,7.1,17,7.2,16.9z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Stack9Icon;
