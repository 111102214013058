import { isArray } from 'lodash';
// transform { bar: 'a', foo: 'b' } into 'bar=a&foo=b'
const serializeQuery = json =>
  Object.keys(json)
    .filter(
      key =>
        (!isArray(json[key]) && json[key] !== undefined) || isArray(json[key]),
    )
    .map(key => `${key}=${encodeURIComponent(json[key])}`)
    .join('&');

const deserializeQuery = (query): any => {
  if (!query) {
    return null;
  }

  let q = decodeURIComponent(query);
  if (q.indexOf('?') === 0) {
    q = q.slice(1);
  }
  const pairs = q.split('&');
  const result = {};
  pairs.forEach(pair => {
    const keyValue = pair.split('=');
    const value = keyValue[1].includes(',')
      ? keyValue[1]?.split(',').map(decodeURIComponent)
      : decodeURIComponent(keyValue[1]);

    result[keyValue[0]] = value || '';
  });
  return result;
};

const decodeURIComponentSafely = uri => {
  try {
    return decodeURIComponent(uri);
  } catch (e) {
    console.log('URI Component not decodable: ' + uri);
    return uri;
  }
};

export { serializeQuery, deserializeQuery, decodeURIComponentSafely };
