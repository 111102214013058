import { Handle } from 'react-flow-renderer';

import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

/**
 * SuccessOutcomeNode
 * custom react flow node
 * styles needs to be inline
 */
const SuccessOutcomeNode = ({ data }) => (
  <div
    className="react-flow__node-default"
    style={{
      background: 'var(--white)',
      border: '1px solid var(--green)',
      color: 'var(--green)',
    }}
  >
    <Handle
      type="target"
      // tscheck
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      position="left"
      style={{ background: 'var(--green)' }}
    />

    <div>{capitalize(data.label)}</div>
  </div>
);

SuccessOutcomeNode.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SuccessOutcomeNode;
