import { Nav, Navbar } from 'react-bootstrap';

import PropTypes from 'prop-types';

import './EntityActionBar.scss';

const EntityActionBar = ({ onRefLoaded }) => (
  <Navbar collapseOnSelect className="entity-actionbar border-bottom py-2">
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="w-100">
        <div className="w-100" ref={el => onRefLoaded(el)} />
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

EntityActionBar.propTypes = {
  onRefLoaded: PropTypes.any.isRequired,
};

export default EntityActionBar;
