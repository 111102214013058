const ActionTypes = {
  FETCH_SCHEMAS_REQUEST: '[App] Fetch Schemas Request',
  FETCH_SCHEMAS_SUCCESS: '[App] Fetch Schemas Success',
  FETCH_SCHEMAS_FAIL: '[App] Fetch Schemas Fail',
  FETCH_ENTITY_JSON_SCHEMA_REQUEST: '[App] Fetch Menu Items Request',
  FETCH_ENTITY_JSON_SCHEMA_SUCCESS: '[App] Fetch Menu Items Success',
  FETCH_ENTITY_JSON_SCHEMA_FAIL: '[App] Fetch Menu Items Fail',
};

const initialState = {
  isLoading: false,
  entityTypes: [],
  entityJson: null,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_SCHEMAS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        entityTypes: [],
      };
    }

    case ActionTypes.FETCH_SCHEMAS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        entityTypes: action.payload,
      };
    }

    case ActionTypes.FETCH_SCHEMAS_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    case ActionTypes.FETCH_ENTITY_JSON_SCHEMA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        entityJson: null,
      };
    }

    case ActionTypes.FETCH_ENTITY_JSON_SCHEMA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        entityJson: action.payload,
      };
    }

    case ActionTypes.FETCH_ENTITY_JSON_SCHEMA_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    default:
      throw new Error();
  }
};

export { initialState, ActionTypes };

export default reducer;
