import PropTypes from 'prop-types';

import './style.scss';
import { ENTITY_LOG_ACTION_OBJECT } from '../../constants/entityLogActions';

const EntityHistoryCircle = ({ action }) => {
  const icon = ENTITY_LOG_ACTION_OBJECT[action]?.icon;
  const background = ENTITY_LOG_ACTION_OBJECT[action]?.background;
  return (
    <div
      style={{ background }}
      className="history-circle circle circle-primary rounded-circle"
    >
      <i className={`${icon} fa-md icon`} />
    </div>
  );
};

EntityHistoryCircle.propTypes = {
  action: PropTypes.string.isRequired,
};

export default EntityHistoryCircle;
