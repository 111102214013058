/* eslint-disable no-console */
import { useContext, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

import LayoutWithMenu from '../components/Layout/LayoutWithMenu';
import DashboardLoader from '../components/Loaders/DashboardLoader';
import { AppContext } from '../contexts/AppContext';

const AppDashboard = () => {
  const heightOffset = 234;
  const { appKey } = useParams<{ appKey: string }>();
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const editMode = !!urlSearchParams.get('edit');

  const {
    actions: { fetchQuickSightEmbedUrl },
    state: { apps, isLoadingDashboard, dashboardEmbedUrl, dashboardAdapter },
  } = useContext(AppContext);
  const selectedApp = apps.find(app => app.key === appKey);

  useEffect(() => {
    async function fetchEmbedUrl() {
      await fetchQuickSightEmbedUrl(appKey, editMode);
    }
    fetchEmbedUrl();
  }, [appKey, fetchQuickSightEmbedUrl, editMode]);

  useEffect(() => {
    if (dashboardEmbedUrl && dashboardAdapter === 'quicksight') {
      embedDashboard({
        url: dashboardEmbedUrl,
        container: document.getElementById('container'),
        scrolling: 'no',
        height: `${window.innerHeight - heightOffset}px`,
        width: '100%',
      });
    }

    if (
      isLoadingDashboard === false &&
      dashboardEmbedUrl === null &&
      dashboardAdapter &&
      dashboardAdapter !== 'quicksight' &&
      selectedApp &&
      selectedApp.menu.length
    ) {
      const [firstMenuItem] = selectedApp.menu;

      if (firstMenuItem.entityKey) {
        history.push(`/apps/${selectedApp.key}/${firstMenuItem.entityKey}`);
      } else if (
        firstMenuItem.item_groups?.length &&
        firstMenuItem.item_groups[0].items.length
      ) {
        const { entityKey } = firstMenuItem.item_groups[0].items[0];
        history.push(`/apps/${selectedApp.key}/${entityKey}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardEmbedUrl, dashboardAdapter, selectedApp]);

  return (
    <LayoutWithMenu>
      <div className="container-fluid" data-cy="app-dashboard-container">
        <h3 className="text-primary font-weight-bold">
          {selectedApp ? selectedApp.name : ''}
        </h3>

        {isLoadingDashboard && !dashboardEmbedUrl && (
          <DashboardLoader
            width={window.innerWidth - 60}
            height={window.innerHeight - heightOffset}
          />
        )}

        {dashboardEmbedUrl && <div id="container" />}

        {!isLoadingDashboard && dashboardAdapter !== 'quicksight' && (
          <div className="row">
            <div className="col-4">
              <div className="card">
                <div className="card-body text-center">
                  <i className="far fa-chart-line fa-lg fa-5x py-5" />
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="card">
                <div className="card-body text-center">
                  <i className="far fa-chart-line fa-lg fa-5x py-5" />
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="card">
                <div className="card-body text-center">
                  <i className="far fa-chart-line fa-lg fa-5x py-5" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </LayoutWithMenu>
  );
};

export default AppDashboard;
