import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import cx from 'classnames';

import FieldLabel from './FieldLabel';

interface IColorPickerFieldProps {
  name: string;
  defaultValue: any;
  fieldItem: {
    ui_component_options?: {
      readOnly?: boolean;
    };
    placeholder?: string;
    description?: string;
  };
}

const ColorPickerField = ({
  name,
  defaultValue = '',
  fieldItem,
}: IColorPickerFieldProps) => {
  const [hasColor, setHasColor] = useState(!!defaultValue);
  const { errors, control } = useFormContext();
  const placeholder = fieldItem?.placeholder;
  const readOnly = fieldItem.ui_component_options?.readOnly || false;
  const colorInputProps = {
    name: `color-${name}`,
    className: 'form-control',
    placeholder,
    id: name,
    readOnly,
  };

  const inputTextProps = {
    className: 'form-control',
    placeholder,
    id: `text-${name}`,
    readOnly,
  };

  return (
    <FieldLabel fieldItem={fieldItem}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          return (
            <div
              id={`field-${name}`}
              className={cx(
                'd-flex align-items-center form-control overflow-hidden',
                { 'is-invalid': errors[name] },
              )}
            >
              <input
                type="text"
                {...inputTextProps}
                value={hasColor ? value : ''}
                onChange={({ target: { value } }) => {
                  onChange(hasColor ? value : undefined);
                  setHasColor(!!value);
                }}
              />
              {hasColor && (
                <button
                  className="close"
                  onClick={() => {
                    setHasColor(false);
                    onChange(undefined);
                  }}
                >
                  <span aria-hidden="true">×</span>
                  <span className="sr-only">Close</span>
                </button>
              )}
              <input
                type="color"
                {...colorInputProps}
                value={value}
                style={{ width: '50px' }}
                onChange={({ target: { value } }) => {
                  onChange(hasColor ? value : undefined);
                  setHasColor(!!value);
                }}
              />
            </div>
          );
        }}
      />
      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}
    </FieldLabel>
  );
};

export default ColorPickerField;
