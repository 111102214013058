import PropTypes from 'prop-types';

import './EntityHistoryList.scss';
import EntityHistoryListItem from '../EntityHistoryListItem/EntityHistoryListItem';

const EntityHistoryList = ({ histories, schema }) => {
  if (!histories?.length) {
    return <h6>there is no history to show</h6>;
  }

  return (
    <div className="EntityHistoryList">
      {histories.map(history => (
        <div className="history" key={history.id}>
          {schema && (
            <EntityHistoryListItem history={history} schema={schema} />
          )}
        </div>
      ))}
    </div>
  );
};

EntityHistoryList.propTypes = {
  histories: PropTypes.array,
  schema: PropTypes.any.isRequired,
};
EntityHistoryList.defaultProps = {
  histories: undefined,
};

export default EntityHistoryList;
