import { IEntitySchemaDefinition, IJsonEntityView } from '@april9/stack9-sdk';

import EntityTable from 'components/Entity/EntityTable/EntityTable';
import Paginate from 'components/Paginate/Paginate';
import ENTITY from 'constants/Entity';
import { useDialogManager } from 'contexts/DialogManagerContext';

import KanbanView from './KanbanView/KanbanView';
import MapView from './MapView/MapView';
import TimelineView from './TimelineView/TimelineView';

interface IEntityViews {
  currentPage?: number;
  defaultSort?: string[];
  entityKey: string;
  listData?: any[];
  pageLimit: number;
  schema: IEntitySchemaDefinition;
  selectedView: IJsonEntityView;
  total?: number;

  handlePage: (pageParams: any) => any;
  handleSorting: (sort: any) => any;
  afterSaveCallback: (entity: any) => any;
  afterDeleteCallback: (entity: any) => any;
}

const EntityViews = ({
  currentPage = 0,
  defaultSort = [],
  entityKey,
  listData = [],
  pageLimit,
  schema,
  selectedView,
  total = 0,

  // Functions
  afterDeleteCallback,
  afterSaveCallback,
  handlePage,
  handleSorting,
}: IEntityViews) => {
  const { openDialog } = useDialogManager();

  if (selectedView.viewType === ENTITY.VIEWS.LIST) {
    return (
      <>
        {listData && schema && (
          <EntityTable
            view={selectedView}
            schema={schema}
            data={listData}
            defaultSort={defaultSort}
            onRowSelected={event => {
              if (!event.node.selected) return;

              openDialog({
                entityKey,
                entityId: event.node.data.id,
                afterSaveCallback,
                afterDeleteCallback,
              });

              // Unselect row selected, so I can re-select the same again
              event.node.setSelected(false);
            }}
            onColumnSorted={handleSorting}
          />
        )}

        {total > 0 && (
          <Paginate
            pageLimit={pageLimit}
            total={total}
            currentPage={currentPage}
            onPageChanged={handlePage}
          />
        )}
      </>
    );
  } else if (selectedView.viewType === ENTITY.VIEWS.MAP && listData && schema) {
    return (
      <MapView
        data={listData}
        options={selectedView.viewOptions as any}
        entityKey={entityKey}
        fields={schema.fields}
        afterSaveCallback={afterSaveCallback}
        afterDeleteCallback={afterDeleteCallback}
      />
    );
  } else if (selectedView.viewType === ENTITY.VIEWS.KANBAN && listData) {
    return (
      <KanbanView
        data={listData}
        options={selectedView.viewOptions as any}
        schema={schema}
        onCardClick={cardId => {
          openDialog({
            entityKey,
            entityId: parseInt(cardId, 10),
            afterSaveCallback,
            afterDeleteCallback,
          });
        }}
      />
    );
  } else if (selectedView.viewType === ENTITY.VIEWS.TIMELINE) {
    return (
      <TimelineView
        data={listData}
        options={selectedView.viewOptions as any}
        onItemClick={itemId => {
          openDialog({
            entityKey,
            entityId: parseInt(itemId, 10),
            afterSaveCallback,
            afterDeleteCallback,
          });
        }}
      />
    );
  }

  return <span>Selected view doesn't exist.</span>;
};

export default EntityViews;
