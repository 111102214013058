import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { get } from 'core/fetch';
import PropTypes from 'prop-types';

import FieldLabel from '../FieldLabel';

const fetchEntities = async () => {
  try {
    return await get(`/schema`);
  } catch (err) {
    throw new Error(err);
  }
};
const fetchApps = async () => {
  try {
    return (await get(`/app`)).apps;
  } catch (err) {
    throw new Error(err);
  }
};
const PrivilegiesMatrix = ({ name, defaultValue, fieldItem }) => {
  const { scope } = fieldItem.ui_component_options;
  const { register } = useFormContext();
  const [entities, setEntities] = useState<Array<any>>([]);

  const appPrivilegies = [{ key: 'view', label: 'View' }];
  const entityPrivilegies = [
    { key: 'create', label: 'Create' },
    { key: 'read', label: 'Read' },
    { key: 'write', label: 'Write' },
    { key: 'delete', label: 'Delete' },
    { key: 'comment', label: 'Comment' },
    { key: 'attach', label: 'Attach' },
    { key: 'print', label: 'Print' },
    { key: 'export', label: 'Export' },
  ];
  const context = {
    entity: {
      fetch: fetchEntities,
      privilegies: entityPrivilegies,
    },
    app: {
      fetch: fetchApps,
      privilegies: appPrivilegies,
    },
  };
  const colWidth = `${100 / (entityPrivilegies.length + 1)}%`;
  useEffect(() => {
    let unmounted = false;
    context[scope].fetch().then(items => {
      if (!unmounted) {
        setEntities(items);
      }
    });
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FieldLabel fieldItem={fieldItem}>
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: colWidth }}>&nbsp;</th>
            {context[scope].privilegies.map(priv => (
              <th
                style={{ width: colWidth }}
                className="text-center"
                key={priv.label}
              >
                {priv.label}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <table className="table">
          <tbody>
            {entities.map(entity => (
              <tr key={entity.key}>
                <td style={{ width: colWidth }}>{entity.name}</td>
                {context[scope].privilegies.map(priv => (
                  <td
                    key={`${entity.key}${priv.key}`}
                    style={{ width: colWidth }}
                    className="text-center"
                  >
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        name={`${name}[${entity.key}].${priv.key}`}
                        id={`${name}[${entity.key}].${priv.key}`}
                        type="checkbox"
                        ref={register}
                        defaultChecked={defaultValue?.[entity.key]?.[priv.key]}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`${name}[${entity.key}].${priv.key}`}
                      >
                        &nbsp;
                      </label>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </FieldLabel>
  );
};

PrivilegiesMatrix.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.object.isRequired,
};

PrivilegiesMatrix.defaultProps = {
  defaultValue: '',
};

export default PrivilegiesMatrix;
