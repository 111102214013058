import {
  FilterFormTag,
  FilterFormKeyField,
  FilterFormKeyValue,
  FilterFormValue,
  FilterFormSingleValue,
} from '@april9/stack9-react';
import {
  FieldItem,
  S9Query,
  WorkflowDefinition,
  FilterFieldsSchema,
} from '@april9/stack9-sdk';
import { mapValues, pick } from 'lodash';
import moment from 'moment';

import { UIComponents } from 'constants/fieldTypes';

import {
  normalizeValueToArray,
  workoutFilterCriteria,
} from '../constants/filterCriterias';

const hasValue = (s: any): boolean => s !== undefined && s !== null && s !== '';

const parseWorkflowValues = (values: Array<number>) => {
  const workflowSteps = values.filter(value => value > 0);
  const workflowOutcome = values
    .filter(value => value < 0)
    .map(value => value * -1);

  return { workflowSteps, workflowOutcome };
};

const parseWorkflowTags = (
  valueOrValues: any,
  workflowDefinition: WorkflowDefinition,
): Array<FilterFormTag> => {
  const workflowArrayValues = normalizeValueToArray(valueOrValues);
  const { workflowOutcome, workflowSteps } = parseWorkflowValues(
    workflowArrayValues.map(value => parseInt(value, 10)),
  );

  const stepDefaultValue = workflowDefinition.steps
    .filter(item => workflowSteps.includes(item.order))
    .map<FilterFormTag>(step => ({
      label: step.name,
      value: step.order.toString(),
    }));

  const outcomeDefaultValue = workflowOutcome.map<FilterFormTag>(outcome => {
    const labels = {
      1: workflowDefinition.outcome.success.label,
      2: workflowDefinition.outcome.failure.label,
    };

    return {
      label: labels[outcome],
      value: outcome * -1,
    };
  });

  return [...stepDefaultValue, ...outcomeDefaultValue];
};

const parseFormKeyFieldToKeyValue = (
  data: FilterFormKeyField,
  filterFieldsSchema: FilterFieldsSchema,
): FilterFormKeyValue => {
  return mapValues(data, ({ value }, fieldKey: string) =>
    parseFromFormFilterValue(value, filterFieldsSchema[fieldKey]),
  );
};

const parseFromFormSingleValue = (
  value: FilterFormSingleValue,
  { db_type, ui_component_options }: FieldItem,
): any => {
  if (!hasValue(value)) {
    return '';
  }

  switch (db_type) {
    case 'decimal':
      return parseFloat(value.toString());
    case 'boolean':
      return value.toString().toLowerCase() === 'true';
    case 'datetime':
      const dateFormat = ui_component_options?.time
        ? 'YYYY-MM-DDTHH:mm:ssZ'
        : 'YYYY-MM-DD';

      return moment(value).format(dateFormat);
    case 'integer':
      return parseInt(value.toString(), 10);
    default:
      return value.toString();
  }
};

const parseFromFormFilterValue = (
  value: FilterFormValue,
  filterFieldSchema: FieldItem,
): FilterFormValue => {
  if (Array.isArray(value)) {
    return value
      .map((valueOrTag: FilterFormTag | FilterFormSingleValue) =>
        parseFromFormSingleValue(
          (valueOrTag as FilterFormTag)?.value ||
            (valueOrTag as FilterFormSingleValue),
          filterFieldSchema,
        ),
      )
      .filter(item => hasValue(item));
  }

  return parseFromFormSingleValue(value, filterFieldSchema);
};

const parseFormFilterDefaultValue = (
  value: FilterFormValue,
  { db_type, ui_component_options, ui_component }: FieldItem,
  workflowDefinition: WorkflowDefinition,
): undefined | FilterFormSingleValue | FilterFormTag[] | Date[] => {
  if (!value) {
    return undefined;
  }

  if (ui_component === 'WorkflowStep') {
    return !workflowDefinition?.steps
      ? undefined
      : parseWorkflowTags(value, workflowDefinition);
  }

  switch (db_type) {
    case 'datetime':
      if (!Array.isArray(value)) throw new Error('datetime must be a range');

      const dateFormat = ui_component_options?.time
        ? 'YYYY-MM-DDTHH:mm:ssZ'
        : 'YYYY-MM-DD';

      return value.map(itemValue => moment(itemValue, dateFormat).toDate());

    case 'decimal':
    case 'integer':
      return value as number;
    case 'boolean': {
      const values = normalizeValueToArray(value);

      return values.map((itemValue: any) => ({
        label: itemValue.toString().toLowerCase() === 'true' ? 'Yes' : `No`,
        value: itemValue,
      }));
    }
    default: {
      const values = normalizeValueToArray(value);

      return values.map((itemValue: any) => ({
        label: itemValue,
        value: itemValue,
      }));
    }
  }
};

const defaultLabel = 'Not selected';

const parseFormFilterLabel = (
  value: FilterFormValue | undefined,
  { db_type, ui_component_options, ui_component }: FieldItem,
  workflowDefinition?: WorkflowDefinition,
): string => {
  if (!value) {
    return defaultLabel;
  }

  if (ui_component === 'WorkflowStep') {
    return !workflowDefinition?.steps
      ? defaultLabel
      : (value as Array<FilterFormTag>).map(item => item.label).join(', ');
  }

  switch (db_type) {
    case 'datetime':
      if (!Array.isArray(value)) throw new Error('datetime must be a range');

      const dateFormat = ui_component_options?.time
        ? 'DD/MM/YYYY HH:mm'
        : 'DD/MM/YYYY';

      return value
        .map(itemValue => moment(itemValue).format(dateFormat))
        .join(' - ');

    default: {
      const values = Array.isArray(value) ? value : [value];

      if (!values.length) {
        return defaultLabel;
      }

      return values
        .map(itemValue => {
          return itemValue?.label || itemValue.toString();
        })
        .join(', ');
    }
  }
};

/**
 * Function responsible to get criteria from each applied filter
 * and populate "$where" and "$withRelated" objects
 *
 * @returns {$where, $withRelated}
 */
const workoutQuery = (filters): S9Query => {
  /** Preparing $where object */
  let $where: any = {};

  Object.entries(filters).forEach(
    ([key, filter]: [key: string, filter: any]) => {
      if (!filter.criteria) return;

      /* checking if there's a multi select filter to prepare the
       * "$where" object, including the "$exists" object.
       * Release https://github.com/april9-digital-consulting/stack9-core/blob/develop/release_notes/2021-03-23-AE-243.md
       *
       * e.g {
       *        $where: {
       *  ------> $exists: {
       *             RELATED_COLUMN_1: {},
       *             ...
       *          }
       *        }
       *     }0
       */
      if (
        filter.withRelated &&
        filter.uiComponent === UIComponents.MultiDropDown
      ) {
        $where.$exists = {
          ...$where.$exists,
          ...{
            [filter.withRelated]: {
              $where: { id: filter.criteria },
            },
          },
        };
        return;
      }

      $where = {
        ...$where,
        ...{ [adjustPropNameToQueryablePropName(key)]: filter.criteria },
      };
    },
  );

  // if ($where._workflow_current_step) {
  //   const hasToDeleteTheWorkflowKey = !$where._workflow_current_step
  //     ._workflow_current_step;
  //   $where = {
  //     ...$where,
  //     ...$where._workflow_current_step,
  //   };
  //   if (hasToDeleteTheWorkflowKey) {
  //     delete $where._workflow_current_step;
  //   }
  // }

  /** Preparing $withRelated and $select objects */
  const $withRelated = Object.entries(filters)
    .filter(
      ([, filter]: [_: any, filter: any]) =>
        !!filter.withRelated &&
        filter.uiComponent !== UIComponents.MultiDropDown,
    )
    .map(([, filter]: [_: any, filter: any]) => filter.withRelated);

  /** Preparing $select object */
  const $select = Object.entries(filters)
    .filter(
      ([, filter]: [_: any, filter: any]) =>
        !!filter.select && filter.uiComponent !== UIComponents.MultiDropDown,
    )
    .map(([, filter]: [_: any, filter: any]) => filter.select);

  return { $where, $withRelated, $select };
};

const adjustPropNameToQueryablePropName = (expr: string) => {
  // takes: 'a:b.c' or 'a.b.c' or 'a:b:c' transform to 'a:b.c'
  return expr
    .replace(/\:/g, '.')
    .replace(/\./g, ':')
    .replace(new RegExp(':([^:]*)$'), '.$1');
};

const removeLastSegment = (expr: string) => {
  const segs = expr.split(':');
  segs.pop();

  return segs.join('.');
};

const transformFiltersDataBeforeSubmit = (
  filterFormValues: FilterFormKeyValue,
  filterFields: FilterFieldsSchema,
): S9Query => {
  const values: any = mapValues(
    filterFormValues,
    (fieldValue: FilterFormValue, fieldKey: string) => {
      const filterFieldSchema = filterFields[fieldKey];

      const value = parseFromFormFilterValue(fieldValue, filterFieldSchema);
      const operatorCriteria = workoutFilterCriteria(filterFieldSchema);

      return {
        [fieldKey]: fieldValue,
        uiComponent: filterFieldSchema.ui_component,
        criteria:
          hasValue(value) && operatorCriteria
            ? operatorCriteria.getFilterValue(
                value,
                adjustPropNameToQueryablePropName(fieldKey),
              )
            : null,
        withRelated: fieldKey.includes(':')
          ? removeLastSegment(fieldKey)
          : undefined,
        select: fieldKey.replaceAll(':', '.'),
      };
    },
  );

  // workout withRelated fields
  const newValues = Object.keys(values).reduce((prevValue, fieldName) => {
    const filterFieldSchema = filterFields[fieldName];
    const fieldProps = prevValue;

    if (filterFieldSchema.relationshipOptions) {
      const fieldCriteria = prevValue[fieldName];
      delete fieldProps[fieldName];

      return {
        ...fieldProps,
        [adjustPropNameToQueryablePropName(
          `${filterFieldSchema.relationshipOptions.name}.id`,
        )]: {
          ...fieldCriteria,
          withRelated: filterFieldSchema.relationshipOptions.name,
        },
      };
    }

    return fieldProps;
  }, values);

  return workoutQuery(newValues);
};

const filterValidFormValues = (filterFields, filterFormValues) => {
  const filterFieldsKeys = Object.keys(filterFields);
  const validFieldValues = Object.keys(
    filterFormValues,
  ).filter(filterFormValue => filterFieldsKeys.includes(filterFormValue));

  return pick(filterFields, validFieldValues);
};

export {
  parseWorkflowValues,
  parseFormFilterLabel,
  parseFromFormFilterValue,
  parseFormFilterDefaultValue,
  parseFormKeyFieldToKeyValue,
  transformFiltersDataBeforeSubmit,
  filterValidFormValues,
};
