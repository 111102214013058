import PropTypes from 'prop-types';

import s from './IronBody.module.scss';

const IronBody = ({ children }: React.PropsWithChildren<any>) => (
  <div className={s.root}>{children}</div>
);

IronBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default IronBody;
