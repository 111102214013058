/* eslint-disable no-plusplus */

import { useState, useEffect } from 'react';
import ReactFlow, {
  Background,
  Controls,
  ReactFlowProvider,
  addEdge,
} from 'react-flow-renderer';

import PropTypes from 'prop-types';

import {
  StartNode,
  SuccessOutcomeNode,
} from 'components/ThirdLibraries/ReactFlow/CustomNodes';

import s from './VisualWorkflow.module.scss';

const WORKFLOW_STEPS = {
  ALL: 'ALL',
};

const WORKFLOW_OUTCOMES = {
  success: 1,
  failure: 2,
};

const WORKFLOW_STYLES = {
  newRecord: {
    fill: 'var(--warning)',
    background: 'var(--white)',
    backgroundOpacity: 0.8,
  },
  proceedToNextStep: {
    fill: 'var(--primary)',
    background: 'var(--white)',
    backgroundOpacity: 0.8,
  },
  proceedToSuccessOutcome: {
    fill: 'var(--success)',
    background: 'var(--white)',
    backgroundOpacity: 0.8,
  },
  proceedToFailOutcome: {
    fill: 'var(--danger)',
    background: 'var(--white)',
    backgroundOpacity: 0.8,
  },
};

const getConnectionStyle = action => {
  const goesToOutcome = action.to.outcome;
  const goesToFailureOutcome = goesToOutcome === WORKFLOW_OUTCOMES.failure;

  if (goesToOutcome && goesToFailureOutcome) {
    return WORKFLOW_STYLES.proceedToFailOutcome;
  }
  if (goesToOutcome) {
    return WORKFLOW_STYLES.proceedToSuccessOutcome;
  }
  return WORKFLOW_STYLES.proceedToNextStep;
};

/**
 * VisualWorkflow to prepare the workflow initialState before calling ReactFlow
 *
 *
 * Workflow Actions array example
 * [
    from: ["ALL"]
    key: "approve"
    name: "Approve Record"
    to: {outcome: 1}
  ]
  * */
const VisualWorkflow = ({ workflow }) => {
  const [elements, setElements] = useState<Array<any>>([]);

  useEffect(() => {
    const { actions, steps } = workflow.definition;

    /**
     * PREPARING STEPS NODES
     */
    const stepsNodes = steps.map(step => ({
      id: step.key,
      data: { label: step.name },
      position: step.position,
      type: 'default',
      targetPosition: 'left',
      sourcePosition: 'right',
    }));

    /**
     * PREPARING CONNECTIONS (ACTIONS)
     */
    const firstStep: any = stepsNodes[0] || {};
    const connections: Array<any> = [
      {
        // connecting start with first step
        id: `estart-${firstStep.id}`,
        source: 'start',
        label: 'NEW',
        target: firstStep.id,
        arrowHeadType: 'arrowclosed',
        labelStyle: {
          fill: WORKFLOW_STYLES.newRecord.fill,
          fontWeight: 700,
        },
      },
    ];

    for (let ac = 0; actions.length > ac; ac++) {
      for (let f = 0; actions[ac].from.length > f; f++) {
        const target =
          actions[ac].to.step ||
          (actions[ac].to.outcome &&
            `outcome-${actions[ac].to.outcome.toString()}`);

        const from = actions[ac].from[f];
        const { name } = actions[ac];
        const connectionStyle = getConnectionStyle(actions[ac]);

        // if from includes ALL, create connections
        // using all available steps
        if (from.includes(WORKFLOW_STEPS.ALL)) {
          for (let st = 0; steps.length > st; st++) {
            const fromStep = steps[st].key;

            connections.push({
              id: `e-${fromStep}-${target}`,
              source: `${fromStep}`,
              target,
              label: name,
              labelBgPadding: [],
              arrowHeadType: 'arrowclosed',
              labelStyle: {
                fill: connectionStyle.fill,
                fontWeight: 700,
              },
            });
          }

          continue;
        }

        // proceed to next step
        connections.push({
          id: `e-${from}-${target}`,
          source: `${from}`,
          target,
          label: name,
          arrowHeadType: 'arrowclosed',
          labelBgPadding: [8, 4],
          labelBgBorderRadius: 4,
          labelStyle: {
            fill: connectionStyle.fill,
            fontWeight: 700,
          },
          labelBgStyle: {
            fill: connectionStyle.background,
            fillOpacity: connectionStyle.backgroundOpacity,
          },
        });
      }
    }

    // OUTCOME NODES
    const outcomes = Object.entries(workflow.definition.outcome).map(
      ([key, value]: [key: string, value: any]) => {
        const outcomeValue = WORKFLOW_OUTCOMES[key];
        const isSuccess = outcomeValue === WORKFLOW_OUTCOMES.success;

        return {
          id: `outcome-${outcomeValue}`,
          type: isSuccess ? 'successOutcomeNode' : 'output',
          data: { label: value.label },
          targetPosition: 'left',
          position: value.position,
          style: isSuccess
            ? { borderColor: WORKFLOW_STYLES.proceedToNextStep.fill }
            : {},
        };
      },
    );

    const nodes: Array<any> = [
      {
        id: 'start',
        type: 'startNode',
        data: { label: 'Start' },
        position: { x: 0, y: 0 },
        sourcePosition: 'right',
      },
      ...stepsNodes,
      ...outcomes,
    ];

    setElements([...nodes, ...connections]);
  }, [workflow]);

  if (elements.length === 0) return <></>;

  return <Workflow initialElements={elements} />;
};

const onWorkflowLoad = reactFlowInstance => reactFlowInstance.fitView();

const nodeTypes = {
  startNode: StartNode,
  successOutcomeNode: SuccessOutcomeNode,
};

const Workflow = ({ initialElements }) => {
  const [elements, setElements] = useState(initialElements);
  const onConnect = params => setElements(el => addEdge(params, el));

  // Used to get positions of the node
  // eslint-disable-next-line no-unused-vars
  const onNodeDragStop = (ev, node) => {
    // eslint-disable-next-line no-console
    console.log('All elements', elements);
    // eslint-disable-next-line no-console
    console.log('Element', node);
  };

  return (
    <div className={s.root}>
      <ReactFlowProvider>
        <ReactFlow
          elements={elements}
          onConnect={onConnect}
          onNodeDragStop={onNodeDragStop}
          nodeTypes={nodeTypes}
          nodesDraggable
          nodesConnectable={false}
          elementsSelectable={false}
          // paneMoveable={false}
          zoomOnScroll={false}
          zoomOnDoubleClick={false}
          onLoad={onWorkflowLoad}
          snapToGrid
        >
          <Background />
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};

Workflow.propTypes = {
  initialElements: PropTypes.array.isRequired,
};

VisualWorkflow.propTypes = {
  workflow: PropTypes.object.isRequired,
};

export default VisualWorkflow;
